import type { QuoteDocument, QuoteTotals } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import get from 'lodash/get'
import { roundPrice } from '../util'

export function discount(quote: QuoteDocument): QuoteTotals {
  const newTotal = { ...quote.totals }
  // We only calculate multi discounts if multi discount quote
  if (quote.discount_key === 'multi') return newTotal

  const roundPrices = quote.options?.roundPrices

  const discount = typeof quote.discount !== 'undefined' && quote.discount !== null ? parseNumber(quote.discount) : 0
  const discountType = (quote.discount_type && quote.discount_type.value) || 'percentage'

  const price = parseNumber(
    quote.totals && quote.totals.after_adjustment ? quote.totals.after_adjustment : quote.totals.price
  )

  const additionalWorkPrice = parseNumber(get(quote, 'totals.additionalWork.price', 0))
  const additionalWorkMaterials = parseNumber(get(quote, 'totals.additionalWork.materials', 0))

  const addedOptionsPrice = parseNumber(get(quote, 'totals.addedOptions.price', 0))
  const addedOptionsMaterials = parseNumber(get(quote, 'totals.addedOptions.materials', 0))

  const totalPrice = price + additionalWorkPrice + additionalWorkMaterials + addedOptionsPrice + addedOptionsMaterials

  let totalDiscount = 0
  if (discountType === 'percentage') {
    totalDiscount = Math.ceil(totalPrice * discount * 0.01 * 100) / 100
  } else {
    totalDiscount = discount
  }

  newTotal.total_discount = quote.version >= 10 ? roundPrice(totalDiscount, roundPrices) : totalDiscount

  newTotal.after_discount = totalPrice - newTotal.total_discount

  return newTotal
}
