export function roundPrice(n: number, round?: 'none' | 'up' | 'round'): number {
  const sanitized = n || 0
  if (round === 'up') {
    return Math.ceil(sanitized)
  }
  if (round === 'round') {
    return Math.round(sanitized)
  }
  return Math.round(sanitized * 100) / 100
}
