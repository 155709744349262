import type { Theme } from '@material-ui/core'
import { makeStyles, Typography, useTheme } from '@material-ui/core'
import Skeleton from '@ui/core/Skeleton'
import classnames from 'classnames'
import React, { useEffect, useRef } from 'react'

import type { WithUseStyles } from '../styles'
import Table, { TableBody, TableCell, TableHead, TableRow } from '../Table'
import TablePagination from './TablePagination'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'fit-content',
    minWidth: '100%'
  },
  table: {},
  tableBody: {},
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 100
  },
  noResults: {
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    color: theme.palette.text.disabled
  },
  shimCell: {
    padding: 16
  }
}))

export interface ResultsTableProps<T = any> {
  className?: string
  rows: T[]

  /**
   * Renders the element for the row
   */
  renderRow: (row: T, index: number) => JSX.Element

  shimHeight?: string

  /**
   * A <TableRow> component to render into <TableHead>
   *
   */
  columns: JSX.Element

  /**
   * The current page
   */
  page: number

  /**
   * Total pages of data available
   */
  pages?: number

  totalRows?: number
  /**
   * Number of rows to show per page
   */
  rowsPerPage: number

  /**
   * If true, the rows will be shimmed
   */
  loading?: boolean

  /**
   * Shown when there is no data
   */
  noResultsMessage?: React.ReactNode

  /**
   * Disables pagination and hides the footer
   */
  disablePagination?: boolean

  showFirstLastPagination?: boolean

  /**
   * If true, we only have the current page of data
   */
  pagesLoadedIndividually?: boolean

  /**
   * Called when the page is changed by the user
   */
  onPageChange?: (page: number) => any
}

/**
 * A table for showing the results of a query or search with pagination
 */
function ResultsTable({
  rows,
  renderRow,
  rowsPerPage,
  page,
  totalRows,
  columns,
  loading,
  noResultsMessage,
  disablePagination,
  onPageChange,
  showFirstLastPagination,
  pagesLoadedIndividually,
  className,
  shimHeight = '16px',
  classes: propClasses
}: ResultsTableProps & WithUseStyles<typeof useStyles>) {
  const classes = useStyles({ classes: propClasses })
  const theme = useTheme<Theme>()
  const currentPage = useRef(page)
  const rowsForPage = pagesLoadedIndividually
    ? rows
    : getRowsForPage({ rows, rowsPerPage, page: currentPage.current - 1 })
  const isFirstPage = currentPage.current <= 1
  const pages = Math.ceil(totalRows / rowsPerPage)
  const isLastPage = currentPage.current >= pages || !pages || pages === 1

  function handleChangePage(event, nextPage) {
    if (onPageChange) {
      onPageChange(nextPage)
    }

    currentPage.current = nextPage
  }

  useEffect(() => {
    currentPage.current = page
  }, [page])

  const noResults = rowsForPage.length === 0 && !loading
  const pageHasRows = rowsForPage.length > 0

  return (
    <div className={classnames(className, classes.root)}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>{columns}</TableHead>
        {pageHasRows && !loading && <TableBody className={classes.tableBody}>{rowsForPage.map(renderRow)}</TableBody>}
      </Table>
      {/* because our table is made of divs, this is the best way to have a single cell span the entire row*/}
      {loading && (
        <Table data-testid={'loading-table'} className={classes.table}>
          <TableBody className={classes.tableBody}>
            {Array.from({ length: rowsPerPage }).map((_, index) => (
              <TableRow key={index}>
                <TableCell className={classes.shimCell}>
                  <Skeleton width="100%" height={shimHeight} color={theme.palette.grey[200]} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {noResults && (
        <div className={classes.noResults}>
          <Typography variant="h6" align="center">
            {noResultsMessage}
          </Typography>
        </div>
      )}
      {!disablePagination && !noResults && (
        <TablePagination
          currentPage={currentPage.current}
          pages={pages}
          showFirstLastPagination={showFirstLastPagination}
          disablePrevPage={loading || isFirstPage}
          disableNextPage={loading || isLastPage}
          disableFirstPage={loading || isFirstPage}
          disableLastPage={loading || isLastPage}
          onChangePage={handleChangePage}
        />
      )}
    </div>
  )
}

function getRowsForPage(args: { rows: any[]; page: number; rowsPerPage: number }) {
  const { rows, page, rowsPerPage } = args
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
}

ResultsTable.defaultProps = {
  page: 1,
  noResultsMessage: 'No results',
  selected: []
}

export default ResultsTable
