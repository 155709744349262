import { Button, ClickAwayListener, FormControl, IconButton, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { DayPickerSingleDateController } from 'react-dates'
import type { Moment } from 'moment'
import moment from 'moment'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ClearIcon from '@material-ui/icons/Clear'
import classnames from 'classnames'
import { useBreakpoint } from '@ui/core'
import { InputField, ResponsivePopper, DatePickerMonthDropdown, useDatePickerStyles } from '@ui/paintscout'
import type { SingleDatePickerProps } from '@ui/paintscout'
import QuoteHeaderCard from '../QuoteHeaderCard'

export interface HeaderCardDatePickerProps extends SingleDatePickerProps {
  isEditable?: boolean
  showBorder?: boolean
  headerCardClasses?: Record<string, string>
}

export default function HeaderCardDatePicker(props: HeaderCardDatePickerProps) {
  const {
    date,
    label,
    disabled,
    clearable,
    PopperProps,
    modalBreakpoint = 'sm',
    onChange,
    isEditable,
    showBorder,
    headerCardClasses
  } = props
  const mobile = useBreakpoint((breakpoints) => breakpoints.down(modalBreakpoint))
  const dateFormat = 'MMM D, YYYY'

  const [tableHeight, setTableHeight] = useState(0)

  const anchorRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    recalculateTableHeight()
  }, [anchorEl])

  const recalculateTableHeight = () => {
    const elements = document.getElementsByClassName('CalendarMonthGrid_month__horizontal')
    if (elements.length > 0) {
      const height = (elements[0] as any).offsetHeight
      setTableHeight(height)
    }
  }

  const classes = useDatePickerStyles({ isSingle: true, tableHeight })

  const handleClickAway = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (e.target instanceof HTMLElement && e.target.className.includes('top-navigation')) {
      // This means the click was in a nested dropdown, so we don't want to close the date picker
      return
    }
    setAnchorEl(null)
  }

  const handleChange = (date: Moment) => {
    setAnchorEl(null)
    onChange(date)
  }

  const handleClear = (e) => {
    e.stopPropagation()
    onChange(null)
    setAnchorEl(null)
  }

  const handleClick = () => {
    if (isEditable) {
      setAnchorEl(anchorEl ? null : anchorRef.current)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl fullWidth>
        <QuoteHeaderCard
          classes={headerCardClasses}
          clickable={isEditable}
          title={label}
          onClick={handleClick}
          showBorder={showBorder}
          text={
            <InputField
              className={classnames(classes.input, classes.headerCardInput)}
              placeholder="-TBD-"
              inputRef={anchorRef}
              readOnly
              fullWidth
              value={date ? date.format(dateFormat) : ''}
              onClick={handleClick}
              disabled={disabled}
              endAdornment={
                clearable &&
                date && (
                  <IconButton size="small" onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                )
              }
            />
          }
        />
        <ResponsivePopper
          classes={{ dialogRoot: classes.popper, dialogContent: classes.dialogContent }}
          open={!!anchorEl}
          PopperProps={{
            anchorEl,
            placement: 'bottom-start',
            modifiers: {
              flip: {
                enabled: false
              }
            },
            disablePortal: true,
            ...PopperProps
          }}
          modalBreakpoint={modalBreakpoint}
          footer={
            mobile && (
              <div className={classes.mobileFooter}>
                <Button variant="text" onClick={() => setAnchorEl(null)}>
                  cancel
                </Button>
              </div>
            )
          }
        >
          <Paper className={classes.paper}>
            <DayPickerSingleDateController
              transitionDuration={0}
              onFocusChange={(_) => {}}
              date={date}
              onDateChange={handleChange}
              daySize={45}
              noBorder
              focused
              initialVisibleMonth={() => date || moment()}
              navPrev={
                <IconButton className={classes.navButton} size="small">
                  <ChevronLeftIcon fontSize={mobile ? 'default' : 'large'} />
                </IconButton>
              }
              navNext={
                <IconButton className={classnames(classes.navButton, classes.rightNavButton)} size="small">
                  <ChevronRightIcon fontSize={mobile ? 'default' : 'large'} />
                </IconButton>
              }
              onPrevMonthClick={() => {
                recalculateTableHeight()
              }}
              onNextMonthClick={() => {
                recalculateTableHeight()
              }}
              renderMonthElement={(args) => <DatePickerMonthDropdown {...args} />}
            />
          </Paper>
        </ResponsivePopper>
      </FormControl>
    </ClickAwayListener>
  )
}
