import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import ActivityTitle from '../ActivityTitle'
import ActivityFooter from '../ActivityFooter'
import ActivityContent from '../ActivityContent'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import Typography from '../../Typography'
import ActivityOwner from '../ActivityOwner'
import capitalize from 'lodash/capitalize'
import QuoteLabel from '../QuoteLabel'

const useStyles = makeStyles<Theme, ActivityTypeComponentProps>((theme: Theme) => ({
  reason: {
    color: theme.palette.text.secondary
  }
}))

export default function QuoteStatusChangedActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props
  const classes = useStyles(props)

  let status = capitalize(activity.details.quote?.status ?? activity.details.invoice?.status)
  if (status === 'Onhold') {
    status = 'On Hold'
  }
  const reason = activity.details.events?.[0]

  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          status ? (
            <>
              <QuoteLabel activity={activity} /> status changed to <Highlight>{status}</Highlight>
            </>
          ) : (
            <>
              <QuoteLabel activity={activity} /> status changed
            </>
          )
        ) : status ? (
          <>
            Status changed to <Highlight>{status}</Highlight>
          </>
        ) : (
          <>Status changed</>
        )}
        <ActivityOwner activity={activity} />
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {reason && (
        <ActivityContent>
          <Typography variant="subtitle2" className={classes.reason}>
            {reason}
          </Typography>
        </ActivityContent>
      )}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}
