import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { Auth0User, UserPreferencesDocument, Permission } from 'paintscout'
import type { Auth0UserWithClaims, UserProfile, UserValue } from './UserContext'
import UserContext from './UserContext'
import { getHighestRole } from '@paintscout/util'

export interface UserProviderProps {
  user: Auth0User
  preferences: UserPreferencesDocument
  remainingQuotes?: number
  children?: React.ReactNode | ((context: UserValue) => React.ReactNode)
  togglePaymentExpiryNoticeCache?: () => void
}

export default function UserProvider({
  togglePaymentExpiryNoticeCache,
  children,
  user,
  preferences,
  remainingQuotes
}: UserProviderProps) {
  const [trialExpired, setTrialExpired] = useState(false)
  const trialExpiry = user?.app_metadata?.company?.trialExpiry
  const [paymentExpiryNotice, setPaymentExpiryNotice] = useState(
    user.app_metadata?.company?.paymentExpiryNotice ?? false
  )
  const updatePaymentExpiryNotice = async (paymentExpiryNotice: boolean) => {
    setPaymentExpiryNotice(paymentExpiryNotice)
    if (togglePaymentExpiryNoticeCache) {
      togglePaymentExpiryNoticeCache()
    }
  }

  useEffect(() => {
    setPaymentExpiryNotice(user.app_metadata?.company?.paymentExpiryNotice ?? false)
    if (togglePaymentExpiryNoticeCache) {
      togglePaymentExpiryNoticeCache()
    }
  }, [user, user?.app_metadata?.company?.paymentExpiryNotice])

  const updateTrialExpired = useCallback(
    (date: number) => {
      const updatedTrialExpired = trialExpiry !== null && trialExpiry < date
      if (updatedTrialExpired !== trialExpired) setTrialExpired(updatedTrialExpired)
    },
    [trialExpired, trialExpiry]
  )
  const isSubscribed = user.app_metadata?.company?.nonBilling
    ? true
    : (user.app_metadata?.active &&
        (user.app_metadata?.company?.active || user.app_metadata?.company?.subscription?.status === 'active')) ??
      false

  const masqueradeCompanyId = window.localStorage.getItem('masqueradeCompanyId')

  const value: UserValue = useMemo(() => {
    const roles = user?.app_metadata?.roles ?? []

    return {
      user,
      preferences,
      highestRole: getHighestRole(roles),
      isSuperadmin: roles.indexOf('superadmin') !== -1,
      isSupport: roles.indexOf('support') !== -1,
      isCorporate: roles.indexOf('corporate') !== -1,
      isMasquerading: !!masqueradeCompanyId,
      isAdmin: roles.indexOf('admin') !== -1,
      isSales: roles.indexOf('sales') !== -1,
      isCrew: roles.indexOf('crew') !== -1,
      isViewer: roles.indexOf('viewer') !== -1,
      isDirty: false,
      isSubscribed,

      hasPermissions: hasPermissions(user),
      updateTrialExpired,
      paymentExpiryNotice,
      updatePaymentExpiryNotice,

      // legacy
      profile: getProfile({ user, preferences }),

      nonBilling: user?.app_metadata?.company?.nonBilling,
      isTrial: user?.app_metadata?.company?.isTrial,
      trialExpired,
      trialExpiry,
      billingId: user.app_metadata?.company?.billingId,
      remainingQuotes
    }
  }, [
    user,
    preferences,
    remainingQuotes,
    trialExpired,
    trialExpiry,
    isSubscribed,
    updateTrialExpired,
    paymentExpiryNotice
  ])

  const childrenFn = children as (context: UserValue) => React.ReactNode

  return (
    <UserContext.Provider value={value}>
      {typeof children === 'function' ? childrenFn(value) : children}
    </UserContext.Provider>
  )
}

function hasPermissions(user: Auth0User) {
  return (permissions: Permission[], any?: boolean) => {
    let hasAny = false
    let hasAll = true

    permissions.forEach((permission: Permission) => {
      const hasPermission = (user?.app_metadata?.permissions ?? []).includes(permission)
      if (!hasPermission) {
        hasAll = false
      }
      if (hasPermission) {
        hasAny = true
      }
    })

    return any ? hasAny : hasAll
  }
}

function getProfile({
  user,
  preferences
}: {
  user: Auth0UserWithClaims
  preferences: UserPreferencesDocument
}): UserProfile {
  const { app_metadata, user_metadata } = user
  const { roles } = app_metadata

  return {
    _id: app_metadata.user._id,
    firstName: user_metadata.firstName,
    lastName: user_metadata.lastName,
    phoneNumber: user_metadata.phoneNumber,
    title: user_metadata.title,
    email: user.email,
    preferences,
    roles,
    active: app_metadata ? app_metadata.active : undefined
  }
}
