import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { useClientOptions } from '@ui/paintscout'
import React, { useMemo } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Checkbox } from '@ui/paintscout'
import { FormSectionTitle } from '@ui/paintscout'
import { Collapse } from '@ui/paintscout'
import { Typography } from '@ui/paintscout'
import classnames from 'classnames'
import type { QuoteItemSection, QuoteItemView, RenderableItem } from '@paintscout/util/builder'
import { getDetails, getFeature, getItems, getQuoteOptions } from '@paintscout/util/builder'
import WorkOrderCard from '../WorkOrderCard'
import { useQuote } from '../../../../context'

const useStyles = makeStyles<Theme, WorkOrderTableProps>(
  (theme) => ({
    root: {},
    avoidPageBreak: {
      '@media print': {
        pageBreakInside: 'avoid',
        pageBreakAfter: 'always'
      }
    },
    readOnly: {},

    hoursToggle: {
      display: 'flex',
      alignItems: 'center'
    },

    hidePrint: {
      '@media print': {
        display: 'none'
      }
    },

    li: {
      '&::before': {
        content: "''",
        color: 'white'
      },
      '@media print': {
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2)
      }
    },
    cards: {
      listStyleType: 'none',
      paddingLeft: 0,
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2)
      },
      '@media print': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2)
      }
    },
    singleColumnCards: {
      gridTemplateColumns: '1fr',
      '@media print': {
        display: 'block'
      },
      '& li': {
        '@media print': {
          marginBottom: theme.spacing(2)
        }
      }
    },
    avoidPageBreakLi: {
      '@media print': {
        breakInside: 'avoid'
      }
    },
    avoidPageBreakLiNested: {
      '& li': {
        '@media print': {
          pageBreakInside: 'avoid'
        }
      }
    }
  }),
  { name: 'WorkOrderTable' }
)

export interface WorkOrderTableProps {
  classes?: StyleClasses<typeof useStyles>
  onReorder?: (args: { items: any[]; section: QuoteItemSection; view: QuoteItemView }) => void
  onAddClick?: (event: React.MouseEvent<HTMLElement>) => void
  onItemClick?: (event: React.MouseEvent<Element, MouseEvent>, key: string) => void
  onToggle?: (ev: any) => void
  onViewChange?: (event: any, view: string) => void
  onToggleAmount?: (event: React.ChangeEvent<HTMLElement>) => void
  tableView?: QuoteItemView
  section?: QuoteItemSection
  showPrices?: boolean
  showHours?: boolean
  title?: string
  subTitle?: string
  collapsed?: boolean

  showAddButton?: boolean
  showEmpty?: boolean
  showLineItems?: boolean
  rightContent?: React.ReactNode
}

function WorkOrderTable(props: WorkOrderTableProps) {
  const classes = useStyles(props)
  const {
    section,
    title,
    subTitle,
    collapsed,
    tableView,
    showEmpty,
    onToggle,
    onToggleAmount,
    showHours,
    showPrices,
    showLineItems
  } = props
  const { quote, isEditable } = useQuote()
  const { options } = useClientOptions()
  const details = useMemo(() => getDetails({ quote, options }), [quote, options])
  const quoteOptions = getQuoteOptions({ options, quote })
  const columns = options.options.quotes.singleColumnWorkOrder === true ? 1 : 2
  const allowShowHours = !details.totals.hours.useCustom || quoteOptions.allowShowCustomizedTotalHours

  const items = useMemo(() => {
    let items = getItems({
      quote,
      section,
      view: tableView,
      consumer: 'crew',
      options,
      overrideOptions: { showQuantities: true },
      showLineItems,
      showValues: showHours,
      hideDimensions: quoteOptions.hideWorkOrderDimensions
    })

    if (section === 'bid') {
      const addedItems = getItems({
        quote,
        section: 'added-options',
        view: tableView,
        consumer: 'crew',
        options,
        overrideOptions: { showQuantities: true },
        showLineItems,
        showValues: showHours,
        hideDimensions: quoteOptions.hideWorkOrderDimensions
      })

      items = [...items, ...addedItems]
    }
    return items
  }, [quote, tableView])

  if ((collapsed && !isEditable) || (!showEmpty && !items.length)) {
    return null
  }

  const defaultRightContent =
    onToggleAmount && isEditable ? (
      <Typography className={classnames(classes.hoursToggle, classes.hidePrint)}>
        Show Hours
        <Tooltip title={!allowShowHours ? 'Hours cannot be shown because Total Hours have been customized' : ''}>
          <span>
            <Checkbox checked={showHours} disabled={!allowShowHours} onChange={onToggleAmount} />
          </span>
        </Tooltip>
      </Typography>
    ) : null

  const rightContent = props.rightContent ? props.rightContent : defaultRightContent

  const handleItemClick = (ev: React.MouseEvent<Element, MouseEvent>, key: string) => {
    if (props?.onItemClick) {
      props.onItemClick(ev, key)
    }
  }
  const noPdfPageBreak = getFeature({ options, path: 'quotes.noPdfPageBreak' })

  return (
    <div
      className={classnames({
        [classes.avoidPageBreak]: !noPdfPageBreak,
        [classes.readOnly]: !isEditable,
        [classes.hidePrint]: !items.length
      })}
    >
      {title && (
        <FormSectionTitle
          title={title}
          subTitle={subTitle}
          showToggle={isEditable && !!onToggle}
          toggleValue={!collapsed}
          onToggle={onToggle}
          rightContent={rightContent}
        />
      )}

      <Collapse show={!collapsed}>
        <ul
          className={classnames(
            classes.cards,
            columns === 1 ? classes.singleColumnCards : null,
            noPdfPageBreak ? null : classes.avoidPageBreakLiNested
          )}
        >
          {items.map((item: RenderableItem, index: number) => {
            return (
              <li className={classnames(classes.li, noPdfPageBreak ? null : classes.avoidPageBreakLi)} key={index}>
                <WorkOrderCard
                  item={item}
                  showHours={showHours}
                  showPrices={showPrices && section === 'options'}
                  quote={quote}
                  section={section}
                  onClick={(ev) => handleItemClick(ev, item.key)}
                />
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

export default WorkOrderTable
