import { FormikInputField, CheckboxField, Grid, Button, FormikDropdownSelect, AdornmentBox } from '@ui/paintscout'
import { getObjectLabels, uuid } from '@paintscout/util/builder'
import { FastField, Field, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import TaxDiscountLayout from './TaxDiscountLayout'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  defaultCheckbox: {
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      marginLeft: -theme.spacing(1)
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: -theme.spacing(1.5)
    }
  }
}))

export default function DiscountOptions() {
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const classes = useStyles()
  const handleAddDiscountRate = () => {
    const id = uuid()
    setFieldValue('options.options.discounts.order', [...(options.options.discounts.order ?? []), id])
    setFieldValue('options.options.discounts.values', {
      ...options.options.discounts.values,
      [id]: { description: '', value: 0, type: 'percentage', isDefault: false }
    })
  }

  const handleDefaultClick = (discountKey: string) => {
    setFieldValue(
      `options.options.discounts.values[${discountKey}].isDefault`,
      !values.options.options.discounts.values[discountKey]?.isDefault
    )
  }

  const discounts = (options.options.discounts?.order ?? [])
    .map((discountKey) => {
      return options.options.discounts?.values?.[discountKey]
    })
    .filter(Boolean)

  return (
    <div className={classes.root}>
      {discounts.map((discount, index: number) => {
        const discountKey = options.options.discounts.order[index]
        return (
          <DiscountRow
            key={discountKey}
            discountKey={discountKey}
            descriptionName={`options.options.discounts.values[${discountKey}].description`}
            valueName={`options.options.discounts.values[${discountKey}].value`}
            isDefault={options.options.discounts.values[discountKey]?.isDefault}
            handleDefaultClick={handleDefaultClick}
          />
        )
      })}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} lg={4}>
          <Button variant="outlined" color="primary" onClick={handleAddDiscountRate} fullWidth>
            + Add Discount Rate
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

function DiscountRow({
  discountKey,
  descriptionName,
  valueName,
  isDefault,
  handleDefaultClick
}: {
  discountKey: string
  descriptionName: string
  valueName: string
  isDefault?: boolean
  handleDefaultClick?: (discountKey: string) => void
}) {
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const classes = useStyles()
  const { quote: quoteLabel, currency } = getObjectLabels({ options })

  function handleRemove() {
    setFieldValue(
      'options.options.discounts.order',
      options.options.discounts.order.filter((orderItem) => orderItem !== discountKey)
    )
    setFieldValue(
      'options.options.discounts.values',
      Object.fromEntries(Object.entries(options.options.discounts.values).filter(([key]) => key !== discountKey))
    )
  }

  const typeOptions = [
    { value: 'percentage', label: '%' },
    { value: 'amount', label: currency.symbol }
  ]

  return (
    <TaxDiscountLayout
      Description={
        <FastField
          component={FormikInputField}
          name={descriptionName}
          type="text"
          label={'Discount Description'}
          fullWidth
        />
      }
      Value={
        <Field
          component={FormikInputField}
          name={valueName}
          required
          error={!values.options.options.discounts.values[discountKey]?.value}
          format={'taxRate'}
          label={`Discount Rate`}
          placeholder={'Discount Rate'}
          fullWidth
          endAdornment={
            <AdornmentBox>
              <FastField
                component={FormikDropdownSelect}
                nestedDropdown
                name={`options.options.discounts.values[${discountKey}].type`}
                options={typeOptions}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value
                  setFieldValue(`options.options.discounts.values[${discountKey}].type`, value)
                }}
              />
            </AdornmentBox>
          }
        />
      }
      Checkbox={
        <CheckboxField
          className={classes.defaultCheckbox}
          name={`options.options.discounts.values[${discountKey}].isDefault`}
          checked={isDefault}
          label={`Include on New ${quoteLabel.plural}`}
          onClick={(e) => {
            if (e.target.checked) {
              handleDefaultClick(discountKey)
            }
          }}
        />
      }
      handleRemove={handleRemove}
    />
  )
}
