import type { ReactNode } from 'react'
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface LocationTrackerContextType {
  history: string[]
  updateHistory: (path: string) => void
  removeLastEntry: () => void
}

const LocationTrackerContext = createContext<LocationTrackerContextType | undefined>(undefined)

interface LocationTrackerProviderProps {
  children: ReactNode
}

const LocationTrackerProvider: React.FC<LocationTrackerProviderProps> = ({ children }) => {
  const [history, setHistory] = useState<string[]>([])
  const location = useLocation()

  useEffect(() => {
    const fullPath = `${location.pathname}${location.search}`
    updateHistory(fullPath)
  }, [location])

  const updateHistory = (path: string) => {
    setHistory((prevHistory) => {
      if (prevHistory[prevHistory.length - 1] !== path && path !== '/settings') {
        return [...prevHistory, path]
      }
      return prevHistory
    })
  }

  const removeLastEntry = () => {
    setHistory((prevHistory) => prevHistory.slice(0, -1))
  }

  return (
    <LocationTrackerContext.Provider value={{ history, updateHistory, removeLastEntry }}>
      {children}
    </LocationTrackerContext.Provider>
  )
}

export function useLocationTracker(): LocationTrackerContextType {
  const context = useContext(LocationTrackerContext)
  if (context === undefined) {
    throw new Error('useLocationTracker must be used within a LocationTrackerProvider')
  }
  return context
}

export default LocationTrackerProvider
