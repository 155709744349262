import React from 'react'
import ActivityTitle from '../ActivityTitle'
import ActivityFooter from '../ActivityFooter'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import QuoteLabel from '../QuoteLabel'

export default function QuotePaidActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props
  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            <QuoteLabel activity={activity} /> was <Highlight>Fully Paid</Highlight>
          </>
        ) : (
          <>
            <Highlight>Fully Paid</Highlight>
          </>
        )}
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}
