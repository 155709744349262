/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument, QuoteOptions } from 'paintscout'
import { CORRECTIVE_CONSTANT } from '../../../../../constants'
import { trimArea, trimAreaBySection } from '../../../areas'
import { trimGroup } from '../../../groups'
import type { QuoteItemConsumer, QuoteItemSection, RenderableItem } from '../../../index'
import { trimLineItem } from '../../../line-items'
import { filterItem, getQuoteOptions } from '../../../util'
import { convertV1Quote, filterOrderItems, getItemOrder } from '../util'
import { filterAreaItems, getAreaSubItems, getAreaSubstrateValues, getClientNote } from './util'

/**
 * Get Bid, Options, or Archived list of areas for a quote.
 */
export function getItemsByArea(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  keys?: string[]
  showGroups?: boolean
  showLineItems?: boolean
  trimBySubstrateSection?: QuoteItemSection
  hideDimensions?: boolean
}): RenderableItem[] {
  const { options, overrideOptions, section, consumer, keys, trimBySubstrateSection, showGroups, hideDimensions } = args
  const showLineItems = args.showLineItems === false ? false : true
  const includeAreasWithNoSubstrates = overrideOptions?.includeAreasWithNoSubstrates
  const quote = convertV1Quote(args.quote)

  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const areaOrder = getItemOrder('area', section, quote)

  const items = areaOrder
    .map((orderItem) => {
      if (filterOrderItems({ orderItem, showLineItems, keys })) {
        return null
      }

      if (orderItem.type === 'area') {
        // Filter and trim Area by section
        const area = trimBySubstrateSection
          ? trimAreaBySection({ quote, areaKey: orderItem.key, section, options })
          : quote.areas?.[orderItem.key]

        if (!area) {
          return null
        }

        // Get areaSubstrates for given section, and their total value
        const {
          areaSubstrates,
          value,
          sectionAreaSubstrates = []
        } = getAreaSubstrateValues({
          area,
          section,
          quote,
          options,
          orderItem,
          quoteOptions,
          consumer,
          showGroups,
          hideDimensions
        })

        // Filter out un-needed items in area
        if (filterAreaItems(area, section, areaSubstrates, includeAreasWithNoSubstrates)) {
          return null
        }

        // Clean area for display
        const trimmedArea = trimArea({
          item: { ...orderItem, ...area },
          quote,
          options,
          consumer,
          section,
          trimBySubstrateSection
        })

        // Adjust value if area not in section, or section is not bid,
        // unless all substrates area in the same section, then keep the trimmedArea.value
        if (
          ((!filterItem(area, section) || section !== 'bid') &&
            area?.substrateOrder?.length !== areaSubstrates?.length) ||
          (section === 'bid' &&
            area?.substrateOrder?.length === areaSubstrates?.length &&
            !trimmedArea.additionalFields.overridden)
        ) {
          trimmedArea.value = quote.version >= 6 ? Math.floor(value * 100 + CORRECTIVE_CONSTANT) / 100 : value
        }

        // Get sub-items for area display if needed
        const { cleanArea, subItems } = getAreaSubItems({
          trimmedArea,
          areaSubstrates,
          quoteOptions
        })

        // Products from the substrates in given area section
        const areaSubstrateProducts = sectionAreaSubstrates
          .reduce((acc, substrate) => {
            const substrateProducts = substrate?.products ?? []
            return [...acc, ...substrateProducts]
          }, [])
          .filter(Boolean)

        return {
          ...cleanArea,
          subItems: [...subItems, ...getClientNote({ area })],
          products: areaSubstrateProducts
        }
      } else if (orderItem.type === 'line_item') {
        const lineItem = quote.lineItems?.[orderItem.key]
        if (lineItem && filterItem(lineItem, section)) {
          return trimLineItem({ ...orderItem, ...lineItem }, quoteOptions, consumer, options) as RenderableItem
        }
      } else if (orderItem.type === 'group') {
        const group = quote.groups?.[orderItem.key]
        if (group && filterItem(group, section)) {
          return trimGroup(group, options, quote)
        }
      }

      return null
    })
    .filter((item) => item)
  return items
}
