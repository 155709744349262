/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { QuoteDetails } from '../../index'
import { generateProductDescription } from '../../util/generate-product-description/generate-product-description'
import { getQuoteOptions } from '../../util/get-options/get-quote-options'

export function getDetails(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  taxFallback?: boolean
}): QuoteDetails {
  const { quote, options, taxFallback } = args

  const afterAdjustment =
    typeof quote.totals.after_adjustment === 'undefined' ? quote.totals.price : quote.totals.after_adjustment

  const hours = {
    useCustom: !!quote.totals.override_hours,
    default:
      quote.totals.hours +
      quote.totals.prep +
      (quote.totals.additionalWork?.prep ?? 0) +
      (quote.totals.additionalWork?.hours ?? 0) +
      (quote.totals.addedOptions?.prep ?? 0) +
      (quote.totals.addedOptions?.hours ?? 0),
    custom:
      typeof quote.totals.override_hours_value === 'string'
        ? parseFloat(quote.totals.override_hours_value)
        : quote.totals.override_hours_value
  }

  const hoursValue = hours.useCustom ? hours.custom : hours.default
  const itemCount = Object.keys(quote?.lineItems ?? {})?.length

  const { serviceFees, afterDiscountSalesRate } = getQuoteOptions({ quote, options })

  const itemRate = parseInt((afterAdjustment / itemCount).toFixed(2))
  const calculateSalesRateAfterDiscount = !!afterDiscountSalesRate

  const addedPrice = quote.totals?.addedOptions?.price + quote.totals?.addedOptions?.materials
  let salesRate = calculateSalesRateAfterDiscount
    ? quote.totals?.sales_rate_after_discount ?? quote.totals.after_discount / hoursValue
    : quote.totals?.sales_rate ?? (afterAdjustment + addedPrice) / hoursValue

  if (isNaN(salesRate) || salesRate === Infinity || salesRate === -Infinity) {
    salesRate = 0
  }
  salesRate = Math.floor(salesRate * 100) / 100

  const showTotal = typeof quote.totals.show_total === 'undefined' ? true : quote.totals.show_total

  const afterTax = quote.totals.after_tax
  const amountPaid = quote.totals?.amount_paid ?? 0
  const balanceDue = quote.totals?.balance_due ?? afterTax - amountPaid
  const showBalanceDue = quote?.totals?.showBalanceDue ?? true

  const source = {
    ...(quote?.source ?? {}),
    id: quote?.source?.id ?? '',
    provider: quote?.source?.provider ?? ''
  }

  const discount = getDiscount(quote)

  return {
    hourlyRate: quote.hourly_rate,
    clientVisible: !!(quote.clientVisible ?? true),
    crewVisible: !!(quote.crewVisible ?? true),
    notes: {
      client: quote.client_note ? quote.client_note : '',
      internal: quote.internal_note ? quote.internal_note : '',
      products: quote.productString
        ? quote.productString
        : { default: generateProductDescription({ quote, options }), useCustom: false, custom: '' }
    },
    tax: {
      rate: quote.tax_rate ? quote.tax_rate : 0,
      total: quote.totals.total_tax ? quote.totals.total_tax : 0,
      type: 'percentage',
      description: quote.tax_description,
      details: quote?.tax_details
        ? quote.tax_details
        : taxFallback
          ? [
              {
                key: 'custom',
                rate: quote.tax_rate ?? 0,
                description: quote.tax_description,
                laborRate: quote.tax_rate ?? 0,
                materialRate: quote.tax_rate ?? 0
              }
            ]
          : []
    },
    totalAdjustment: {
      rate: quote.totalAdjustment?.rate ? quote.totalAdjustment.rate : 0,
      type: quote.totalAdjustment?.type ?? 'value',
      description: quote.totalAdjustment?.description ?? '',

      isShown: false,
      total: quote.totals.total_adjustment
    },
    discount,
    revision: quote.revision,
    source,
    serviceFees,
    totals: {
      show: showTotal,
      showBalanceDue,
      price: quote.totals.price,
      afterAdjustment,
      afterDiscount: quote.totals.after_discount,
      afterTax,
      hours,

      labour: quote.totals.materials ? quote.totals.price - quote.totals.materials : quote.totals.price,
      materials: quote.totals.materials ? quote.totals.materials : 0,
      additionalWork:
        quote.totals.additionalWork && quote.totals.additionalWork.price ? quote.totals.additionalWork.price : 0,
      additionalMaterials:
        quote.totals?.additionalWork && quote.totals.additionalWork?.materials
          ? quote.totals.additionalWork?.materials
          : 0,
      addedOptions: quote.totals.addedOptions && quote.totals.addedOptions.price ? quote.totals.addedOptions.price : 0,
      addedOptionsMaterials:
        quote.totals?.addedOptions && quote.totals.addedOptions?.materials ? quote.totals.addedOptions?.materials : 0,
      pending: quote.totals.pending && quote.totals.pending.price ? quote.totals.pending.price : 0,

      salesRate,
      itemRate,
      amountPaid,
      balanceDue
    }
  }
}

function getDiscount(quote) {
  let type
  if (typeof quote.discount_type === 'string') {
    type = quote.discount_type
  } else if (quote.discount_type?.value) {
    type = quote.discount_type.value
  } else {
    type = 'percentage'
  }

  return {
    key: quote.discount_key,
    keys: quote.discounts?.map((discount) => discount.key),
    rate: quote.discount ? quote.discount : 0,
    type,
    description: quote.discount_description,
    // isShown: typeof quote.discount_isShown !== 'undefined' ? quote.discount_isShown : true,
    isShown: true,
    total: quote.totals.total_discount,
    discounts: quote.discounts
  }
}

/*
  quote.totals = {
    after_discount
    after_tax,
    amount_paid,
    balance_due,
    price,
    total_discount,
    total_tax
  }

 */
