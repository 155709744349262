import type { OptionsDocument, Permission } from 'paintscout'
import { getHighestRole } from '../../../users/getHighestRole'

// !! PERMISSIONS MUST BE KEPT IN SYNC WITH UPDATE APP METADATA AND ADD TO TOKEN PERMISSIONS !!
// Can copy this function in and remove the types into the file^
export function getPermissions(optionsDoc: OptionsDocument, roles: string[]): Permission[] {
  const role = getHighestRole(roles)

  let permissions: Permission[] = []
  console.log({ roles })
  if (role === 'superadmin' || role === 'corporate') {
    return [
      'create:quotes',
      'read:all-quotes',
      'write:all-quotes',
      'write:all-accepted-quotes',
      'delete:all-quotes',
      'send:all-quotes',
      'accept:all-quotes',
      'additional-work:all-quotes',

      'create:contacts',
      'read:all-contacts',
      'write:all-contacts',

      'create:invoices',
      'read:all-invoices',
      'write:all-invoices',
      'send:all-invoices',

      'write-presentation-section:all-quotes',
      'change-status-draft:all-quotes',

      'write:account-settings',
      'write:account-settings-integrations',
      'assign:all-documents',
      'accept-payment:all-documents',

      'read:estimator-select'
    ]
  } else if (role === 'admin') {
    permissions = [
      'create:quotes',
      'read:all-quotes',
      'write:all-quotes',
      'write:all-accepted-quotes',
      'delete:all-quotes',
      'send:all-quotes',
      'accept:all-quotes',
      'additional-work:all-quotes',

      'create:contacts',
      'read:all-contacts',
      'write:all-contacts',

      'create:invoices',
      'read:all-invoices',
      'write:all-invoices',
      'send:all-invoices',

      'write-presentation-section:all-quotes',
      'change-status-draft:all-quotes',

      'write:account-settings',
      'write:account-settings-integrations',
      'assign:all-documents',
      'accept-payment:all-documents',

      'read:estimator-select'
    ]
  } else if (role === 'sales') {
    permissions = [
      'create:quotes',
      'read:assigned-quotes',
      'write:assigned-quotes',
      'write:assigned-accepted-quotes',
      'send:assigned-quotes',
      'accept:assigned-quotes',
      'delete:assigned-quotes',
      'additional-work:assigned-quotes',

      'create:contacts',
      'read:all-contacts',
      'write:all-contacts',
      'read:assigned-contacts',
      'write:assigned-contacts',

      'create:invoices',
      'read:assigned-invoices',
      'write:assigned-invoices',
      'send:assigned-invoices',

      'write-presentation-section:assigned-quotes',
      'change-status-draft:assigned-quotes',

      'assign:assigned-documents',
      'accept-payment:assigned-documents'
    ]
  } else if (role === 'crew') {
    permissions = [
      'read:assigned-quotes',
      'additional-work:assigned-quotes',

      'read:all-contacts',
      'read:assigned-contacts',

      'read:assigned-invoices',
      'accept-payment:assigned-documents',

      'read:estimator-select',

      'assign:assigned-documents'
    ]
  } else if (role === 'viewer' || role === 'support') {
    permissions = [
      'read:all-quotes',
      'read:all-contacts',
      'read:all-invoices',
      'read:estimator-select',
      'send:all-quotes',
      'send:all-invoices',
      'additional-work:all-quotes',
      'accept-payment:assigned-documents'
    ]
  } else {
    permissions = []
  }

  const include =
    optionsDoc &&
    optionsDoc.options &&
    optionsDoc.options.permissions &&
    optionsDoc.options.permissions[role] &&
    optionsDoc.options.permissions[role].include
      ? optionsDoc.options.permissions[role].include
      : []
  const exclude =
    optionsDoc &&
    optionsDoc.options &&
    optionsDoc.options.permissions &&
    optionsDoc.options.permissions[role] &&
    optionsDoc.options.permissions[role].exclude
      ? optionsDoc.options.permissions[role].exclude
      : []

  include.forEach(function (permission) {
    permissions.push(permission)
  })

  permissions = permissions.filter(function (permission) {
    return !exclude.includes(permission)
  })

  return permissions
}
