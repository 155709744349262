import type { PaymentActivityDetails } from '@paintscout/api'
import { useActivityQuery } from '@paintscout/api'
import type { DialogProps } from '@ui/paintscout'
import { Checkbox, FormControlLabel, useDialogs } from '@ui/paintscout'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputField,
  Spinner,
  useClientOptions
} from '@ui/paintscout'
import React, { useCallback, useState } from 'react'
import { formatCurrency, getFeatures, setPayments } from '@paintscout/util/builder'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useQuote } from '../../context'
import type { Payment } from 'paintscout'
import * as Sentry from '@sentry/core'
import { Link } from '@material-ui/core'

export interface ViewPaymentDialogProps extends DialogProps {
  activityId: string
  onClose?: () => void
}

const ViewPaymentDialog = (props: ViewPaymentDialogProps) => {
  const { open, activityId, onClose: onConfirm, ...baseDialogProps } = props
  const { options } = useClientOptions()
  const { paymentRequests } = getFeatures({ options })
  const { quote, updateQuote } = useQuote()
  const { dismissDialog } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()
  const dateFormat = options.options.dateFormat.momentValue

  const [payment, setPayment] = useState<Payment>()

  const handleError = (error: any) => {
    Sentry.captureException(error)
    enqueueSnackbar('Unable to fetch payment activity', { variant: 'error' })
    dismissDialog()
  }

  const { loading, data } = useActivityQuery({
    fetchPolicy: 'network-only',
    pollInterval: 120000,
    notifyOnNetworkStatusChange: false,
    variables: {
      id: activityId
    },
    onCompleted: useCallback((data) => {
      if (!data.activity) {
        handleError(new Error(`Unable to fetch payment activity with id: ${activityId}`))
      } else {
        setPayment(quote.payments?.find((payment) => payment.activityId === activityId))
      }
    }, []),
    onError: useCallback((error) => {
      handleError(error)
    }, [])
  })

  const activity = data?.activity
  const paymentMethod = (activity?.details as PaymentActivityDetails)?.method ?? ''

  function handleChange(event: any) {
    const { value, name } = event.target
    setPayment({
      ...payment,
      [name]: value
    })
  }

  function isDirty() {
    const original = quote.payments?.find((payment) => payment.activityId === activityId)?.note
    return original !== payment?.note
  }

  function handleConfirm() {
    if (isDirty()) {
      const payments = quote.payments?.map((quotePayment) =>
        quotePayment.activityId === activityId ? payment : quotePayment
      )
      const updatedQuote = setPayments({ quote, payments, options })
      updateQuote({ quote: updatedQuote })
    }

    if (onConfirm) {
      onConfirm()
    }
  }

  const paymentIntentId = (activity?.details as any)?.providerDetails?.id
  const stripeLink = paymentIntentId ? `https://dashboard.stripe.com/payments/${paymentIntentId}` : undefined
  const hasSurcharge = !!payment?.surcharge && payment.surcharge > 0

  return (
    <Dialog open={open} maxWidth="md" {...baseDialogProps}>
      <DialogTitle>View Payment</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {(loading || !payment) && (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          )}
          {!!activity && !!payment && (
            <>
              {paymentRequests?.enabled && (
                <Grid item xs={12}>
                  <InputField label={'Status'} value={'Paid'} readOnly disabled fullWidth />
                </Grid>
              )}
              {paymentMethod && (
                <Grid item xs={12}>
                  <InputField label={'Type/Method'} value={paymentMethod} readOnly disabled fullWidth />
                </Grid>
              )}
              <Grid item xs={12} sm={hasSurcharge ? 6 : 12}>
                <InputField
                  label={'Amount'}
                  value={formatCurrency({ options, value: payment?.amount - (payment?.surcharge || 0) })}
                  readOnly
                  disabled
                  fullWidth
                />
              </Grid>
              {hasSurcharge && (
                <Grid item xs={12} sm={6}>
                  <InputField
                    label={'+ Credit Card Surcharge'}
                    value={formatCurrency({ options, value: payment?.surcharge })}
                    readOnly
                    disabled
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <InputField
                  label={'Date'}
                  value={moment(activity.details.timestamp).format(dateFormat)}
                  readOnly
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label={'Note'}
                  name={'note'}
                  value={payment?.note ?? ''}
                  fullWidth
                  multiline
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={'This payment is a deposit'}
                  disabled
                  control={<Checkbox name={'deposit'} checked={payment?.deposit} />}
                />
              </Grid>
              {!!stripeLink && (
                <Grid item xs={12}>
                  <Link href={stripeLink} target="_blank">
                    View this payment in Stripe
                  </Link>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        leftButton={
          isDirty() ? (
            <Button onClick={dismissDialog} variant="text">
              Cancel
            </Button>
          ) : null
        }
      >
        <Button type="submit" variant={'contained'} onClick={handleConfirm}>
          {isDirty() ? 'Confirm' : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewPaymentDialog
