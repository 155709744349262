import React from 'react'
import { Field } from 'formik'
import { Grid } from '@material-ui/core'
import { FormikInputField } from '../formik'

const NewClientForm = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field name="options.companyName.value" label="Name" required component={FormikInputField} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="options.companyAddress.value" label="Address" component={FormikInputField} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field name="options.companyPhone.value" label="Phone Number" component={FormikInputField} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field name="options.companyCity.value" label="City" component={FormikInputField} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            name="options.companyProv.value"
            label="State / Province"
            inputProps={{ maxLength: 2 }}
            component={FormikInputField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field name="options.companyPostal.value" label="ZIP / Postal Code" component={FormikInputField} fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default NewClientForm

/*
  <Grid item xs={12} sm={6}>
    <Field type="options.companyEmail.value" name="email" label="Email" component={FormikInputField} fullWidth />
  </Grid> 
  <Grid item xs={12} sm={4}>
    <Field name="options.companyCountry.value" label="Country" component={FormikInputField} fullWidth />
  </Grid> 
*/
