// Quote CSV Templates of various types to use for importing or creating csv files

/**
 * Default Paintscout Public Quote Export CSV Template
 */
const DEFAULT_QUOTES_TEMPLATE = [
  'Number',
  'Status',
  'Date',
  'Std Date',
  'Type',
  'Client',
  'Email',
  'Address',
  'City',
  'State',
  'Zip Code',
  'Phone Number',
  'Job Address',
  'Lead Source',
  'Accepted via',
  'Estimator',
  'Prep (Hours)',
  'Labor (Hours)',
  'Total (Hours)',
  'Labor ($)',
  'Materials ($)',
  'Sub Total ($)',
  'Additional Work Labor ($)',
  'Additional Work Materials ($)',
  'Additional Work Total ($)',
  'After Additional Work ($)',
  'Discount Description',
  'Discount Rate (%)',
  'Discount Total ($)',
  'After Discount ($)',
  'Tax Rate (%)',
  'Tax Description',
  'Total Tax ($)',
  'After Tax ($)',
  'Sales Rate ($/hr)',
  'Sales Rate After Discount ($/hr)',
  'Amount Paid ($)',
  'Balance Due ($)',
  'Total Dimensions Lnft',
  'Total Dimensions Sqft',
  'Total Dimensions Sqft Walls',
  'Total Dimensions Sqft Ceiling',
  'Total Dimensions Sqft Floor',
  'Exterior Sub Total ($)',
  'Interior Sub Total ($)',
  'Exterior Total (Hours)',
  'Interior Total (Hours)',
  'Integration Source Provider',
  'Integration Source ID'
]

/**
 * Estimate Rocket Quote Import CSV Template
 */
const ER_QUOTES_TEMPLATE = [
  'Number',
  'Project Name',
  'Current State',
  'First Name',
  'Last Name',
  'Client Company',
  'Email',
  'Phone',
  'Subtotal',
  'Taxable Amount',
  'Nontaxable Amount',
  'Tax Authority',
  'Tax Rate',
  'Tax Amount',
  'Total',
  'Credit',
  'Paid',
  'Due',
  'Estimated Labor Hours',
  'Estimated Labor Cost',
  'Estimated Expenses',
  'Estimated Total Cost',
  'Estimated Profit',
  'Estimated Gross Margin',
  'Actual Labor Hours',
  'Actual Labor Cost',
  'Actual Expenses',
  'Actual Total Cost',
  'Actual Profit',
  'Actual Gross Margin',
  'Project Owner',
  'Created On',
  'Estimated By',
  'Estimated On',
  'Accepted On',
  'Work Completed By',
  'Work Completed On',
  'Invoiced On',
  'Location Address1',
  'Location Address2',
  'Location City',
  'Location State',
  'Location Zip',
  'Billing Address1',
  'Billing Address2',
  'Billing City',
  'Billing State',
  'Billing Zip',
  'Client Tags',
  'Project Tags',
  'Source'
]

/**
 * Default Contacts Import CSV Template
 */
const CONTACTS_TEMPLATE = [
  'First Name',
  'Last Name',
  'Email',
  'Phone',
  'Phone Ext',
  'Company',
  'Address',
  'City',
  'State / Province',
  'Zip / Postal',
  'Country',
  'Notes',
  'Secondary First Name',
  'Secondary Last Name',
  'Alternate Email',
  'Alternate Phone',
  'Alternate Phone Ext',
  'Lead Source',
  'Integration Source - Id',
  'Integration Source - Name'
]

/**
 * Default Products Import/Export CSV Template
 */
const PRODUCTS_TEMPLATE = [
  'Estimate Types',
  'Name',
  'Price',
  'Unit',
  'Coverage (sqft)',
  'Coverage (lnft)',
  'Coverage (item)'
]

/**
 * Basic Rates Import/Export CSV Template
 */
const BASIC_RATES_TEMPLATE = [
  'Estimate Types',
  'Rate Category',
  'Rate Label (Internal)',
  'Client Label (External)',
  'Rate Type',
  '1 Coats',
  '2 Coats',
  '3 Coats',
  '4 Coats',
  'Default Coats',
  'Default Product',
  'Default Product Coverage',
  'Area Description',
  'Substrate Description',
  'Crew Note'
]

/**
 * Detailed Rates Import/Export CSV Template
 */
const DETAILED_RATES_TEMPLATE = [
  ...BASIC_RATES_TEMPLATE,
  'Prep (hr)',
  'Uses Coats',
  'Default Length',
  'Default Width',
  'Default Height',
  'WorkOrder Only',
  'Customer Quantity Hidden',
  'WorkOrder Quantity Hidden',
  'Show Product',
  'Show Coats',
  'Show Prep',
  'Show Price',
  'Active'
]

export {
  DEFAULT_QUOTES_TEMPLATE,
  ER_QUOTES_TEMPLATE,
  CONTACTS_TEMPLATE,
  PRODUCTS_TEMPLATE,
  BASIC_RATES_TEMPLATE,
  DETAILED_RATES_TEMPLATE
}
