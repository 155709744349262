import RadioGroup from '@material-ui/core/RadioGroup'
import type { DialogProps } from '@ui/paintscout'
import { Switch, Typography } from '@ui/paintscout'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputField,
  InputLabel,
  KeypadInputField,
  MenuItem,
  Select,
  useClientOptions
} from '@ui/paintscout'
import clamp from 'lodash/clamp'
import Radio from '@ui/paintscout/src/Radio'
import {
  formatCurrency,
  getFeatures,
  getObjectLabels,
  getQuoteOptions,
  hasIntegrationInterface
} from '@paintscout/util/builder'
import { Collapse } from '@ui/core'
import type { QuoteDocument, UserPreferencesDocument } from 'paintscout'
import React, { useState } from 'react'
import getDepositAmount from '@paintscout/util/util/getDepositAmount/getDepositAmount'
import PaymentDialogRightContent from '../../PaymentDialogRightContent'
import { makeStyles } from '@material-ui/core'

export interface ConfiguredPaymentRequest {
  amount: number
  note: string
  deposit: boolean
  acceptCC?: boolean
  acceptACH?: boolean
}

export interface ConfigurePaymentDialogProps extends DialogProps {
  quote: QuoteDocument
  preferences: UserPreferencesDocument
  showPaymentTypeToggles?: boolean
  buttonLabel?: string
  backOnCancel?: boolean
  isRequest?: boolean
  hideSurcharge?: boolean
  onCancel: () => void
  onConfirm: (args: ConfiguredPaymentRequest) => void
}

type PaymentType = 'full' | 'deposit' | 'custom'

const useStyles = makeStyles((theme) => ({
  customAmountInput: {
    marginBottom: theme.spacing(1)
  },
  acceptCreditCards: {
    ...theme.typography.h3,
    color: theme.palette.text.secondary
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3)
    }
  }
}))

const ConfigurePaymentDialog = ({
  onCancel,
  onConfirm,
  quote,
  isRequest,
  buttonLabel,
  showPaymentTypeToggles,
  backOnCancel,
  preferences,
  hideSurcharge,
  ...otherProps
}: ConfigurePaymentDialogProps) => {
  const { balance_due } = quote.totals
  const classes = useStyles()

  const { options } = useClientOptions()
  const { currency } = getObjectLabels({ options, quote })

  const hasStripe = hasIntegrationInterface({ preferences, options, integration: 'stripe' })
  const hasPayrix = hasIntegrationInterface({ preferences, options, integration: 'payrix' })
  const hasPayments = (hasStripe || hasPayrix) && quote.totals.balance_due > 0

  const { allowPaymentsOnQuotes, allowedDepositTypes, allowPaymentsOnInvoices, allowACHPaymentsOnInvoices } =
    getQuoteOptions({ options, quote })

  const defaultAcceptCC = quote.is_invoice ? allowPaymentsOnInvoices : allowPaymentsOnQuotes
  const defaultAcceptACH = quote.is_invoice
    ? allowACHPaymentsOnInvoices
    : allowedDepositTypes === 'ach' || allowedDepositTypes === 'both'

  const [acceptCC, setAcceptCC] = useState<boolean>(hasPayments && defaultAcceptCC)
  const [acceptACH, setAcceptACH] = useState<boolean>(hasPayrix && defaultAcceptACH)

  const { surcharge } = getQuoteOptions({ options, quote })
  const surchargeFeature = getFeatures({ options })?.surcharge && !hideSurcharge
  const showSurcharge = surchargeFeature && !!surcharge?.enabled && surcharge?.value > 0 && acceptCC

  const [paymentType, setPaymentType] = useState<PaymentType>('full')
  const [customType, setCustomType] = useState<'percentage' | 'amount'>('amount')
  const [customAmount, setCustomAmount] = useState<{ percentage: number; amount: number }>({
    percentage: 100,
    amount: balance_due
  })

  const [note, setNote] = useState<string>('')

  const leftButton = (
    <Button onClick={onCancel} variant={'text'}>
      {backOnCancel ? '< Back' : 'Cancel'}
    </Button>
  )

  const getPaymentAmount = (result?: boolean) => {
    if (paymentType === 'full') {
      return balance_due
    } else if (paymentType === 'deposit') {
      return getDepositAmount({ quote, options })
    } else {
      if (result || customType === 'amount') {
        return customAmount.amount || 0
      } else {
        return customAmount.percentage || 0
      }
    }
  }

  const handleCustomAmountChange = (key: 'amount' | 'percentage', val: string) => {
    const parsed = clamp(parseFloat(val), 0, key === 'amount' ? balance_due : 100)
    if (key === 'amount') {
      setCustomAmount({ amount: parsed, percentage: (parsed / balance_due) * 100 })
    } else {
      setCustomAmount({ amount: (parsed / 100) * balance_due || 0, percentage: parsed })
    }
  }

  const handleConfirm = () => {
    onConfirm({ amount: getPaymentAmount(true), note, deposit: paymentType === 'deposit', acceptCC, acceptACH })
  }

  return (
    <Dialog onClose={onCancel} fullWidth={true} maxWidth={'md'} {...otherProps}>
      <DialogTitle
        fade={false}
        rightContent={
          <PaymentDialogRightContent
            quote={quote}
            options={options}
            includeSurcharge={false}
            paymentAmount={getPaymentAmount(true)}
            showCurrency={hasPayrix}
          />
        }
      >
        Configure Payment
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3}>
          <Grid container alignContent="space-between" item xs={12} md={5}>
            <div>
              <InputLabel shrink>Payment Amount</InputLabel>
              <RadioGroup row={false} value={paymentType} onChange={(e, val) => setPaymentType(val as PaymentType)}>
                <FormControlLabel
                  label={`Full Amount (${formatCurrency({ options, value: balance_due })})`}
                  value={'full'}
                  control={<Radio color={'primary'} />}
                />
                <FormControlLabel label={'Custom'} value={'custom'} control={<Radio color={'primary'} />} />
              </RadioGroup>
            </div>
            {showPaymentTypeToggles && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.acceptCreditCards}
                    control={<Switch checked={acceptCC} onChange={(_, checked) => setAcceptCC(checked)} />}
                    label={<Typography variant="h6">Accept Credit Cards</Typography>}
                    labelPlacement="end"
                    disabled={!hasPayments}
                  />
                </Grid>
                {hasPayrix && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={classes.acceptCreditCards}
                      control={<Switch checked={acceptACH} onChange={(_, checked) => setAcceptACH(checked)} />}
                      label={<Typography variant="h6">Accept ACH</Typography>}
                      labelPlacement="end"
                      disabled={!hasPayments}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            <Collapse show={paymentType === 'custom'}>
              <KeypadInputField
                className={classes.customAmountInput}
                value={getPaymentAmount().toString()}
                fullWidth={true}
                format={'price'}
                label={`Amount (${customType === 'amount' ? currency.symbol : '%'})`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleCustomAmountChange(customType, e.target.value)
                }
                autoSelect={true}
                endAdornment={
                  <Select
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                    value={customType}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setCustomType(e.target.value as 'amount' | 'percentage')
                    }
                    renderValue={(value: string) => {
                      return <span>{value === 'percentage' ? `%` : `$`}</span>
                    }}
                  >
                    <MenuItem value="amount">{currency.symbol}</MenuItem>
                    <MenuItem value="percentage">%</MenuItem>
                  </Select>
                }
              />
            </Collapse>
            <InputField
              label={isRequest ? 'Note to Customer' : 'Note'}
              value={note}
              multiline={true}
              rows={3}
              name={'note'}
              fullWidth={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
            />
          </Grid>
          {showSurcharge && (
            <Grid container item justifyContent="flex-end">
              <Typography variant="caption" color="textSecondary">
                A {surcharge.value}% surcharge will be added to the total
                {acceptCC && acceptACH && ' if the payment is made by credit card'}.
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        {buttonLabel ? (
          <Button
            onClick={handleConfirm}
            variant={'contained'}
            disabled={paymentType === 'custom' && customAmount.amount === 0}
          >
            {buttonLabel}
          </Button>
        ) : (
          <Button
            onClick={handleConfirm}
            variant={'contained'}
            disabled={paymentType === 'custom' && customAmount.amount === 0}
          >
            {`Pay ${formatCurrency({ options, value: getPaymentAmount(true) })}`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfigurePaymentDialog
