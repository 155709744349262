import type { DialogProps } from '@ui/paintscout'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Spinner,
  Typography,
  useClientOptions,
  useDialogs
} from '@ui/paintscout'
import type { QuoteDocument } from 'paintscout'
import type { CollectPaymentDialogProps } from './CollectPaymentDialog'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { getEmailTemplates } from '@paintscout/util/builder'
import { usePaymentProcessedQuery } from '@paintscout/api'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Done from '@material-ui/icons/Done'
import React from 'react'

export interface PaymentProcessingDialogProps extends DialogProps {
  amount: string
  activityId?: string
  classes: any
  quote: QuoteDocument
  balanceDue: number
  onConfirm: CollectPaymentDialogProps['onConfirm']
  Title: JSX.Element
}

export default function PaymentProcessingDialog(props: PaymentProcessingDialogProps) {
  const { amount: _amount, activityId, classes, quote, balanceDue, onConfirm, Title, ...otherProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const { options } = useClientOptions()

  const [processing, setProcessing] = useState(true)
  const [confirming, setConfirming] = useState(false)

  const { dismissAllDialogs } = useDialogs()

  const paymentReceivedTemplate = getEmailTemplates({ options })?.['payment-received']

  const { data } = usePaymentProcessedQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: quote._id,
      balanceDue,
      activityId
    },
    onError: (error) => {
      console.log('Error processing payment:')
      console.log(error.message)
      dismissAllDialogs()
      if ((error.message || '').includes('Debit card denied')) {
        enqueueSnackbar('Debit cards are not allowed for this transaction.', { variant: 'error' })
      } else {
        enqueueSnackbar('Your payment has been received, but we were unable to process it.', { variant: 'error' })
      }
    },
    onCompleted: () => {
      if (data?.paymentProcessed?.updatedQuote) {
        setProcessing(false)
      }
    }
  })

  async function handleConfirm() {
    if (onConfirm) {
      setConfirming(true)
      await onConfirm({ ...quote, ...data?.paymentProcessed?.updatedQuote })
      setConfirming(false)
    }
  }

  return (
    <Dialog {...otherProps}>
      {Title}
      <DialogContent>
        <Grid
          className={classes.checkContainer}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={1}
        >
          {processing ? (
            <>
              <Grid item>
                <Spinner className={classes.successCheckmark} />
              </Grid>
              <Grid item>
                <Typography align="center" gutterBottom variant="h3">
                  Payment Received! Processing...
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <CheckCircle className={classes.successCheckmark} />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h2">
                  Payment Succeeded
                </Typography>
              </Grid>
              {paymentReceivedTemplate?.enabled && (
                <Grid item>
                  <Typography variant="caption">Please check your email for a receipt.</Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!processing && (
          <Button loading={confirming} onClick={handleConfirm} type="submit" variant={'contained'} icon={Done}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
