import Grid from '@material-ui/core/Grid'
import type { DropdownSelectOption } from '@paintscout/ui/src/DropdownSelect'
import { DropdownSelect } from '@ui/paintscout'
import type { InFilter, ValueFilter } from 'json-to-lucene'
import React from 'react'

export interface UserSearchFiltersProps {
  filters: ValueFilter[]
  onChange?: (filters: ValueFilter[]) => any
}

export const ROLE_OPTIONS = [
  {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'crew',
    label: 'Crew'
  },
  {
    value: 'sales',
    label: 'Sales'
  },
  {
    value: 'viewer',
    label: 'Viewer'
  }
]

const ACTIVE_OPTIONS: DropdownSelectOption[] = [
  { label: 'No Active', value: ['false'] },
  { label: 'Include Active', value: ['true', 'false'] },
  { label: 'Only Active', value: ['true'] }
]

export default function UserSearchFilters({ filters, onChange }: UserSearchFiltersProps) {
  function handleFilterChange(filter: ValueFilter) {
    if (onChange) {
      onChange([...filters.filter(({ id }) => id !== filter.id), filter])
    }
  }
  const roleFilter = filters.find(({ id }) => id === 'app_metadata.roles') as InFilter
  const activeFilter = filters.find(({ id }) => id === 'app_metadata.active') as InFilter

  return (
    <Grid container spacing={2} alignItems={'flex-start'}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="app_metadata.roles"
              label="Role"
              // placeholder="Any Role"
              placeholder={{
                selectPlaceholder: 'Any Role'
              }}
              variant="multi"
              options={ROLE_OPTIONS}
              value={ROLE_OPTIONS.filter((option) => {
                if (!roleFilter) return false
                return option.value === roleFilter?.value[0] || option.value === roleFilter?.value[1]
              })}
              onChange={(option: DropdownSelectOption[]) => {
                const value = option.map((vals) => (vals?.value ? vals.value : vals))
                handleFilterChange({
                  id: 'app_metadata.roles',
                  type: 'equal',
                  value: value
                })
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="app_metadata.active"
              label="Active"
              variant="single"
              options={ACTIVE_OPTIONS}
              value={ACTIVE_OPTIONS.find((option) => {
                if (!activeFilter) return false

                return option.value[0] === activeFilter?.value[0] && option.value[1] === activeFilter?.value[1]
              })}
              onChange={(option: DropdownSelectOption) => {
                handleFilterChange({
                  id: 'app_metadata.active',
                  type: 'equal',
                  value: option.value
                })
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
