/**
 * Get area totals
 * @param  {GenerateAreaDescription}    args
 * @return {string}
 */

import get from 'lodash/get'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { QuoteItemSection } from '../..'
import { getAreaSubstrateDimension } from '../..'
import { makeHtml } from '../../../../util'
import { getAreaSubstrate, isInSection } from '../../area-substrates'
import { getItemSection } from '../get-item-section'
import { getQuoteOptions } from '../get-options'
import mergeLabels from '../merge-labels'

export function generateAreaDescription(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  plainText?: boolean
  filterBySection?: QuoteItemSection
  section?: QuoteItemSection
  inGroup?: boolean
}): string {
  const { quote, areaKey, options, section: argsSection, inGroup } = args
  const { hideSubstrateListDescription } = options?.options ?? {}

  const quoteOptions = getQuoteOptions({ quote })

  const area = quote.areas[areaKey]
  const quantities: { [value: string]: number } = {}
  const prices: { [value: string]: number } = {}
  const coats: { [value: string]: number } = {}
  const products: { [value: string]: string[] } = {}
  const prep: { [value: string]: number } = {}
  const dimensions: { [value: string]: { value: number; unit: string } } = {}
  const areaReportText: string[] = []

  if (
    !area ||
    !area.substrateOrder ||
    ((quoteOptions.stackSubstrates || quoteOptions.showDetailedBreakdown) && !inGroup)
  ) {
    return '<p></p>'
  }

  const areaSection = getItemSection(area)

  // if no section is given - assume the section the area is in.
  const section = argsSection ?? areaSection

  const areaLabels = area.substrateOrder
    .map((orderItem) => {
      const areaSubstrate = getAreaSubstrate({
        quote,
        areaKey,
        options,
        key: orderItem.key,
        full: false
      })
      if (!areaSubstrate || areaSubstrate.workOrderOnly) {
        return null
      }

      const areaSubstrateSection = areaSubstrate.quoteSection

      if (!isInSection({ areaSubstrateSection, areaSection, section })) {
        return null
      }

      const label = areaSubstrate.clientLabel ? areaSubstrate.clientLabel : areaSubstrate.name ?? ''
      const rateType = get(areaSubstrate, 'rate.rateType')

      // This substrate (label) has been grouped in the substrate view, so it shouldn't be included here.
      if (areaSubstrate.inSubstrateGroup) {
        return null
      }

      // Handle quantities on areaSubstrate
      if (!quantities[label]) {
        quantities[label] = 0
      }
      const quantity =
        typeof areaSubstrate.quantity === 'number' ? areaSubstrate.quantity : parseFloat(areaSubstrate.quantity)
      if (rateType === 'quantity' && !areaSubstrate.hideQuantity) {
        quantities[label] += quantity
      }

      // Handle prices & materials on areaSubstrate
      if (!prices[label]) {
        prices[label] = 0
      }
      const price = areaSubstrate.price.useCustom ? areaSubstrate.price.custom : areaSubstrate.price.default
      const materials = areaSubstrate.materials.useCustom
        ? areaSubstrate.materials.custom
        : areaSubstrate.materials.default

      if (areaSubstrate.showPrice) {
        prices[label] += price + materials
      }

      // Handle products on areaSubstrate
      if (!products[label]) {
        products[label] = []
      }
      const { products: areaSubstrateProducts } = areaSubstrate
      for (const areaSubstrateProduct of uniqBy(areaSubstrateProducts, (item) => item.key)) {
        const productString = [areaSubstrateProduct.label, areaSubstrateProduct.color]
          .filter((item) => item)
          .join(' - ')
        if (productString && areaSubstrate.showProduct) {
          products[label].push(productString)
        }
      }

      // Handle dimensions on areaSubstrate
      if (typeof rateType === 'string' && rateType?.includes('ft') && areaSubstrate.showDimensions) {
        const length = areaSubstrate.length.useCustom
          ? areaSubstrate?.length?.custom ?? 0
          : areaSubstrate?.length?.default ?? 0
        const width = areaSubstrate.width.useCustom
          ? areaSubstrate?.width?.custom ?? 0
          : areaSubstrate?.width?.default ?? 0
        const height = areaSubstrate.height.useCustom
          ? areaSubstrate?.height?.custom ?? 0
          : areaSubstrate?.height?.default ?? 0
        const isLnft = rateType === 'lnft'

        const dimensionValue = getAreaSubstrateDimension({
          length,
          width,
          height,
          isRoom: area.area_type.value === 'room',
          rateType
        })
        dimensions[label] = {
          value: (dimensions?.[label]?.value ?? 0) + dimensionValue,
          unit: isLnft ? 'lnft' : 'sqft'
        }
      }
      // Handle coats on areaSubstrate
      if (areaSubstrate.showCoats && areaSubstrate.rate?.useCoats) {
        coats[label] = (coats?.[label] ?? 0) + (areaSubstrate?.coats ?? 0)
      }

      // Handle prep on areaSubstrate
      if (areaSubstrate.showPrep && areaSubstrate.prep) {
        prep[label] = (prep?.[label] ?? 0) + (areaSubstrate?.prep ?? 0)
      }

      if (areaSubstrate.rate.areaReportText) {
        areaReportText.push(areaSubstrate.rate.areaReportText)
      }

      // Hide the "substrate list" part of the description if the option is set
      // but still check if there is an area view description
      if (hideSubstrateListDescription) {
        return null
      }

      return label
    })
    .filter((item) => item)

  // Combine labels to create description
  const descriptionElements = mergeLabels({
    options,
    areaLabels,
    quantities,
    dimensions,
    prices,
    coats,
    prep,
    products
  })

  const descriptionFirstLine = uniq(descriptionElements)
    .map((item) => item.trim())
    .join(quoteOptions.stackSubstrates || quoteOptions.showDetailedBreakdown ? '<br />' : ', ')
  const firstLine = makeHtml(descriptionFirstLine)

  const reportText = uniq(areaReportText.map((item) => makeHtml(item)))
    .filter((item) => item)
    .join('')

  if (!firstLine && !reportText) {
    return '<p></p>'
  }

  return `${firstLine}${reportText}`
}
