import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import DateIcon from '@material-ui/icons/CalendarToday'
import CheckIcon from '@material-ui/icons/Check'
import NoteIcon from '@material-ui/icons/CommentOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DownloadIcon from '@material-ui/icons/GetApp'
import ReceiptIcon from '@material-ui/icons/ReceiptRounded'
import SendIcon from '@material-ui/icons/Send'
import * as Sentry from '@sentry/react'
import {
  formatCurrency,
  getFeature,
  getPayments,
  getQuoteOptions,
  hasIntegrationInterface,
  setPayments,
  uuid
} from '@paintscout/util/builder'
import { isEmailValid } from '@paintscout/util/util'
import { useIsMobile } from '@ui/core'
import {
  ActionButton,
  AlertDialog,
  Button,
  Collapse,
  ConfirmationDialog,
  DialogStackContext,
  FormSectionTitle,
  Hidden,
  StatusDot,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useClientOptions,
  useUser,
  AddItemButton
} from '@ui/paintscout'
import classnames from 'classnames'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import moment from 'moment'
import type { Payment, PayrixPaymentType, QuoteDocument } from 'paintscout'
import React, { useContext } from 'react'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ConfiguredPaymentRequest } from '../../../../dialogs'
import {
  CollectStripePaymentDialog,
  CollectPayrixPaymentDialog,
  ConfigurePaymentDialog,
  QuotePdfDialog,
  SendPaymentRequestDialog,
  TileListDialog,
  ViewPaymentDialog
} from '../../../../dialogs'
import PaymentsDialog from '../../../../dialogs/PaymentsDialog'
import { useSnackbar } from 'notistack'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& thead': {
        background: theme.palette.grey[200]
      },
      '& thead th, & thead th span': {
        color: theme.palette.grey[800]
      },
      '& tbody td': {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`
      }
    },
    readOnly: {
      '& tr': {
        cursor: 'default'
      }
    },
    mobileTitleValue: {
      fontWeight: theme.typography.fontWeightMedium
    },
    mobileStatusDot: {
      marginLeft: 2
    },
    mobileIcons: {
      fontSize: 'inherit',
      verticalAlign: 'middle',
      marginRight: theme.typography.pxToRem(4)
    },
    mobileTitleIcon: {
      fontSize: '0.7rem'
    },
    mobileValueContainer: {
      ...theme.typography.body2,
      color: theme.palette.grey[500],
      width: '100%'
    },
    mobileValue: {
      marginLeft: theme.typography.pxToRem(10)
    },
    mobileEstimatorContainer: {
      ...theme.typography.body2,
      color: theme.palette.grey[500],
      width: '100%'
    },
    fixedCell: {
      width: 100
    },
    dateCell: {
      width: 115
    },
    disabledActionButton: {
      color: 'transparent !important'
    },
    downloadButton: {
      color: theme.palette.text.secondary,
      padding: 0
    },
    underline: {
      width: 'calc(100% - 2px)',
      overflow: 'hidden'
    },
    badgeRoot: {
      display: 'flex'
    },
    marginTop: {
      marginTop: '2px'
    }
  })
}

export interface PaymentsTableProps extends WithStyles<typeof styles> {
  inPresentation?: boolean
  collapsed?: boolean
  onChange: (quote: QuoteDocument) => void
  onToggle?: (ev: any) => void
}

function PaymentsTable({ classes, collapsed, inPresentation, onToggle, onChange }: PaymentsTableProps) {
  const { quote, isEditable, save: saveQuote, updateQuote, isDirty, isSaving } = useContext(QuoteContext)
  const mobile = useIsMobile()
  const { openDialog, dismissDialog, dismissAllDialogs } = useContext(DialogStackContext)
  const { options, clientId } = useClientOptions()
  const { user, preferences } = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { allowPaymentsOnInvoices, allowACHPaymentsOnInvoices } = getQuoteOptions({ quote, options })
  const hasStripe = hasIntegrationInterface({ preferences, options, integration: 'stripe' })
  const hasPayrix = hasIntegrationInterface({ preferences, options, integration: 'payrix' })

  const hasPaymentProcessing = hasStripe || hasPayrix
  const hasPaymentRequests = getFeature({ options, path: 'paymentRequests.enabled' })
  const isPaymentEligible = quote.totals.after_tax > 0 && quote.totals.balance_due > 0

  const canCollectCCPayment = hasPaymentProcessing && allowPaymentsOnInvoices && isPaymentEligible
  const canCollectACHPayment = hasPayrix && allowACHPaymentsOnInvoices && isPaymentEligible
  const canRequestPayment = hasPaymentRequests && isPaymentEligible

  const dateFormat = get(options, 'options.dateFormat.momentValue')
  const payments = getPayments({ quote }).filter((payment) => (inPresentation ? payment.status !== 'pending' : true))
  const hasReceiptColumn = payments.some((payment) => payment.status !== 'pending')

  if (collapsed && !isEditable) {
    return null
  }

  async function handleDownloadPDF(index: number) {
    const payment = payments[index]
    if (isDirty) {
      await saveQuote()
    }
    openDialog(QuotePdfDialog, {
      quote,
      companyId: clientId,
      options,
      silent: true,
      view: 'payment-receipt',
      paymentId: payment.id,
      onConfirm: dismissDialog
    })
  }

  function handleActionClick(actionKey: string, index: number) {
    const payments = getPayments({ quote })
    const payment = payments[index]
    switch (actionKey) {
      case 'delete':
        openDialog(ConfirmationDialog, {
          message: `Are you sure you want to remove this payment${payment?.status === 'pending' ? ' request' : ''}?`,
          onConfirm: async () => {
            dismissDialog()
            payments.splice(index, 1)

            const updatedQuote = setPayments({ quote, payments, options })

            onChange(updatedQuote)
            await saveQuote(updatedQuote)
          },
          onCancel: dismissDialog
        })
        break
      case 'edit':
        handleEditPayment({ paymentIndex: index })
        break
      case 'download':
        handleDownloadPDF(index)
        break
      case 'resend':
        openDialog(SendPaymentRequestDialog, {
          payment: payments[index],
          user,
          preferences,
          isResend: true
        })
        break
      case 'mark-as-paid':
        openDialog(TileListDialog, {
          title: 'Mark as Paid',
          items: [
            {
              key: 'record-payment',
              label: 'Record Payment'
            },
            canCollectCCPayment && {
              key: 'credit',
              label: 'Take Credit Card Payment'
            },
            canCollectACHPayment && {
              key: 'ach',
              label: 'Take ACH Payment'
            }
          ].filter(Boolean),
          onItemSelect: (key: string) => {
            if (key === 'record-payment') {
              handleEditPayment({ paymentIndex: index, backOnCancel: true, markingPaid: true })
            } else {
              handleCollectPayment({
                amount: payment.amount,
                note: payment.note,
                deposit: payment.deposit,
                paymentRequestId: payment.id,
                paymentType: key as PayrixPaymentType
              })
            }
          }
        })
    }
  }

  function handleViewStripePayment(activityId: string) {
    openDialog(ViewPaymentDialog, {
      activityId: activityId,
      onClose: dismissDialog
    })
  }

  function handleEditPayment({
    paymentIndex,
    backOnCancel,
    markingPaid
  }: { paymentIndex?: number; backOnCancel?: boolean; markingPaid?: boolean } = {}) {
    if (payments[paymentIndex]?.activityId && !payments[paymentIndex]?.manual) {
      handleViewStripePayment(payments[paymentIndex]?.activityId)
    } else {
      openDialog(PaymentsDialog, {
        paymentIndex,
        backOnCancel,
        markingPaid,
        onConfirm: dismissAllDialogs,
        onCancel: dismissDialog
      })
    }
  }

  function handleRequestPayment() {
    if (!quote?.contact) {
      openDialog(AlertDialog, {
        title: 'Contact Required',
        message: 'You must add a contact before you can request a payment',
        onConfirm: dismissAllDialogs
      })
      return
    } else if (!isEmailValid(quote.contact.email)) {
      openDialog(AlertDialog, {
        title: 'Invalid Email',
        message: 'You must add a valid email address to your contact before you can request a payment',
        onConfirm: dismissAllDialogs
      })
      return
    }
    openDialog(ConfigurePaymentDialog, {
      quote,
      preferences,
      showPaymentTypeToggles: true,
      isRequest: true,
      buttonLabel: 'Next',
      backOnCancel: true,
      onConfirm: (args: ConfiguredPaymentRequest) => {
        const payment: Payment = {
          ...args,
          id: uuid(),
          type: null,
          date: Date.now(),
          status: 'pending',
          isRequest: true
        }
        openDialog(SendPaymentRequestDialog, {
          payment,
          user,
          preferences
        })
      },
      onCancel: dismissDialog
    })
  }

  function handleConfigurePayment(paymentType: PayrixPaymentType = 'credit') {
    openDialog(ConfigurePaymentDialog, {
      quote,
      preferences,
      backOnCancel: true,
      hideSurcharge: paymentType === 'ach',
      onCancel: dismissDialog,
      onConfirm: (paymentArgs) => handleCollectPayment({ ...paymentArgs, paymentType })
    })
  }

  function handleCollectPayment({
    amount,
    note,
    deposit,
    paymentRequestId,
    paymentType
  }: {
    amount: number
    note: string
    deposit: boolean
    paymentRequestId?: string
    paymentType?: PayrixPaymentType
  }) {
    if (!hasPaymentProcessing) {
      Sentry.captureException(new Error('CollectPaymentDialog tried to open without a valid payment provider'))
      enqueueSnackbar(
        'Something is wrong with your payment integration. Please contact our support team for assistance.',
        { variant: 'error' }
      )
      dismissAllDialogs()
      return
    }
    const PaymentDialog = hasPayrix ? CollectPayrixPaymentDialog : CollectStripePaymentDialog
    openDialog(PaymentDialog, {
      quote,
      amount,
      note,
      options,
      companyId: clientId,
      isDeposit: deposit,
      source: 'collect-payment',
      paymentRequestId,
      paymentType,
      backOnCancel: true,
      onCancel: dismissDialog,
      onConfirm: async (updatedQuote: QuoteDocument) => {
        dismissAllDialogs()
        await updateQuote({ quote: updatedQuote, autosave: false, blockDirty: true })
      }
    })
  }

  function handleAddRequestPayment() {
    if (!canRequestPayment && !canCollectCCPayment && !canCollectACHPayment) {
      handleEditPayment()
      return
    }
    openDialog(TileListDialog, {
      title: 'Add/Request Payment',
      items: [
        {
          key: 'record-payment',
          label: 'Record Payment'
        },
        canRequestPayment && {
          key: 'request-payment',
          label: 'Request Payment'
        },
        canCollectCCPayment && {
          key: 'credit',
          label: 'Take Credit Card Payment'
        },
        canCollectACHPayment && {
          key: 'ach',
          label: 'Take ACH Payment'
        }
      ].filter(Boolean),
      onItemSelect: (key: string) => {
        if (key === 'record-payment') {
          handleEditPayment({ backOnCancel: true })
        } else if (key === 'credit' || key === 'ach') {
          handleConfigurePayment(key)
        } else if (key === 'request-payment') {
          handleRequestPayment()
        }
      }
    })
  }

  const getActions = (index: number) => {
    const payment = payments[index]
    const showResend = !!payments[index] && payment.status === 'pending' && !(payment.amount > quote.totals.balance_due)
    return [
      showResend && {
        key: 'resend',
        icon: SendIcon,
        label: 'Resend'
      },
      payment.status === 'pending' && {
        key: 'edit',
        icon: EditIcon,
        label: 'Edit'
      },
      payment.status === 'pending' && {
        key: 'mark-as-paid',
        icon: CheckIcon,
        label: 'Mark as Paid'
      },
      {
        key: 'delete',
        icon: DeleteIcon,
        label: 'Delete'
      },
      mobile &&
        payment.status === 'paid' && {
          key: 'download',
          icon: DownloadIcon,
          label: 'Download'
        }
    ].filter(Boolean)
  }

  return (
    <div className={`${!isEditable ? classes.readOnly : ''} ${classes.root}`} id="payments">
      <FormSectionTitle title={'Payments'} showToggle={isEditable} toggleValue={!collapsed} onToggle={onToggle} />
      <Collapse show={!collapsed}>
        <Table component="table">
          <TableHead component="thead">
            <TableRow component="tr">
              <Hidden smDown>
                {hasPaymentRequests && (
                  <TableCell component="th" className={classes.fixedCell}>
                    Status
                  </TableCell>
                )}
                <TableCell component="th" className={classes.fixedCell}>
                  Date
                </TableCell>
                <TableCell component="th" className={classes.fixedCell}>
                  Method
                </TableCell>
                <TableCell component="th">Note</TableCell>
                {hasReceiptColumn && <TableCell component="th" className={classes.fixedCell} />}
                <TableCell component="th" className={classes.fixedCell} align="right">
                  Amount
                </TableCell>
                {/* action column */}
                {isEditable && <TableCell component="th" />}
              </Hidden>
              <Hidden mdUp>
                <TableCell component="th">Payment</TableCell>
                <TableCell component="th" />
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody component="tbody">
            {payments.map((payment, index) => (
              <TableRow
                component="tr"
                key={index}
                noBorder={index === payments.length - 1}
                onClick={isEditable ? () => handleEditPayment({ paymentIndex: index }) : null}
              >
                <Hidden smDown>
                  {hasPaymentRequests && (
                    <TableCell component="td">
                      {payment.status === 'pending' && payment.amount > quote.totals.balance_due ? (
                        <Tooltip
                          hideIcon
                          content={
                            'Requested amount exceeds balance due. Your customer will not be able to see this request.'
                          }
                        >
                          <StatusDot error status={payment.status ?? 'paid'}>
                            {startCase(payment.status ?? 'paid')}
                          </StatusDot>
                        </Tooltip>
                      ) : (
                        <StatusDot status={payment.status ?? 'paid'}>{startCase(payment.status ?? 'paid')}</StatusDot>
                      )}
                    </TableCell>
                  )}
                  <TableCell component="td" className={classes.dateCell}>
                    {moment(payment.date).format(dateFormat)}
                  </TableCell>
                  <TableCell component="td">{payment.type}</TableCell>
                  <TableCell component="td">{payment.note}</TableCell>
                  {hasReceiptColumn && (
                    <TableCell component="td">
                      {payment.status !== 'pending' && (
                        <Button
                          disabled={isSaving}
                          id="allow-propagation"
                          onClick={(e) => {
                            handleActionClick('download', index)
                            e.stopPropagation()
                          }}
                          icon={DownloadIcon}
                          className={classes.downloadButton}
                          variant="text"
                        >
                          <u className={classes.underline}>PDF Receipt</u>
                        </Button>
                      )}
                    </TableCell>
                  )}
                  <TableCell component="td" align="right">
                    <Typography
                      style={{ fontWeight: 500 }}
                      value={payment.amount}
                      format={'price'}
                      showUnits={true}
                      variant={'body1'}
                    />
                  </TableCell>
                  {isEditable && (
                    <TableCell isControl={true} component="td" align="right">
                      <ActionButton
                        actions={getActions(index)}
                        onActionClick={(ev, actionKey) => {
                          handleActionClick(actionKey, index)
                        }}
                        disabled={!!payments[index]?.activityId && !payments[index]?.manual}
                        classes={{
                          button:
                            !!payments[index]?.activityId && !payments[index]?.manual
                              ? classes.disabledActionButton
                              : null
                        }}
                      />
                    </TableCell>
                  )}
                </Hidden>

                {/* mobile column */}
                <Hidden mdUp>
                  <TableCell component="td">
                    <div className={classes.mobileTitleValue}>
                      <ReceiptIcon className={classnames(classes.mobileIcons, classes.mobileTitleIcon)} />{' '}
                      {payment.type ? <>{payment.type}</> : null}
                      {payment.type && payment.amount ? <>&nbsp;-&nbsp;</> : null}
                      {payment.amount && payment.amount !== 0 && (
                        <span>{formatCurrency({ options, value: payment.amount })}</span>
                      )}
                      {hasPaymentRequests &&
                        (payment.status === 'pending' && payment.amount > quote.totals.balance_due ? (
                          <Tooltip
                            classes={{
                              badgeRoot: classes.badgeRoot
                            }}
                            hideIcon
                            content={
                              'Requested amount exceeds balance due. Your customer will not be able to see this request.'
                            }
                          >
                            <StatusDot error status={payment.status ?? 'paid'}>
                              {startCase(payment.status ?? 'paid')}
                            </StatusDot>
                          </Tooltip>
                        ) : (
                          <StatusDot status={payment.status ?? 'paid'}>{startCase(payment.status ?? 'paid')}</StatusDot>
                        ))}
                    </div>
                    <div className={classes.mobileValueContainer}>
                      <DateIcon className={classes.mobileIcons} /> {moment(payment.date).format(dateFormat)}
                      {payment.note && (
                        <div>
                          <NoteIcon className={classes.mobileIcons} /> {payment.note}
                        </div>
                      )}
                    </div>
                  </TableCell>
                  {isEditable && (
                    <TableCell isControl={true} component="td" align="right">
                      <ActionButton
                        actions={getActions(index)}
                        onActionClick={(ev, actionKey) => {
                          handleActionClick(actionKey, index)
                        }}
                        disabled={!!payments[index]?.activityId && !payments[index]?.manual}
                      />
                    </TableCell>
                  )}
                </Hidden>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!inPresentation && (
          <AddItemButton
            id="allow-propagation"
            className={!payments.length ? classes.marginTop : ''}
            onClick={handleAddRequestPayment}
          >{`${canRequestPayment ? 'Add/Request' : 'Add'} Payment`}</AddItemButton>
        )}
      </Collapse>
    </div>
  )
}

export default withStyles(styles)(PaymentsTable)
