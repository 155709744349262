import React, { useState, useEffect, useCallback } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles, Popover } from '@material-ui/core'
import { SketchPicker } from 'react-color'
import Card from '@material-ui/core/Card'
import debounce from 'lodash/debounce'
import type { StyleClasses } from '../styles'
import EditIcon from '@material-ui/icons/Edit'
import type { CoreColorPickerProps } from '@ui/core/InlineColorPicker'
import Typography from '../Typography'
import classNames from 'classnames'

export interface BaseColorPickerProps extends CoreColorPickerProps {
  classes?: StyleClasses<typeof useStyles>
  editLabel?: boolean
  hideLabel?: boolean
  disabled?: boolean
}

const useStyles = makeStyles<Theme, BaseColorPickerProps>((theme) => ({
  root: {},
  swatch: {},
  popover: {},
  cover: {},
  colorCard: {},
  label: {},
  popoverPaper: {},
  colorStyle: {},
  disabled: {
    color: theme.palette.text.disabled,
    '&:hover': {
      cursor: 'not-allowed'
    }
  }
}))

function BaseColorPicker(props: BaseColorPickerProps) {
  const { label, onChange, disabled } = props
  const classes = useStyles(props)
  const [color, setColor] = useState(props.value)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setColor(props.value)
  }, [props.value])

  const handleChange = useCallback(
    debounce((newColor: string) => {
      if (onChange) {
        onChange(newColor)
      }
    }, 50),
    [onChange]
  )

  return (
    <div className={classes.root}>
      {!props?.hideLabel && (
        <div className={classNames({ [classes.label]: true, [classes.disabled]: disabled })}>{label}</div>
      )}
      {/* type="button" prevents submitting a form */}
      <button
        disabled={disabled}
        type="button"
        className={classNames({ [classes.swatch]: true, [classes.disabled]: disabled })}
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
      >
        <Card className={classes.colorCard}>
          <div
            style={{ background: color }}
            className={classNames({ [classes.colorStyle]: true, [classes.disabled]: disabled })}
          />
          {props.editLabel ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px 8px' }}>
              <Typography variant={'body1'} className="hex">
                {color}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={'subtitle2'}>Edit</Typography>
                <EditIcon style={{ fontSize: '1rem', color: 'grey', marginLeft: '5px' }} />
              </div>
            </div>
          ) : (
            <div className="hex">{color}</div>
          )}
        </Card>
      </button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(false)}
        PaperProps={{ className: classes.popoverPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SketchPicker
          color={color}
          onChange={({ hex }) => {
            setColor(hex)
            handleChange(hex)
          }}
          disableAlpha={true}
        />
      </Popover>
    </div>
  )
}

export default BaseColorPicker
