/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument, QuoteOptions } from 'paintscout'
import type {
  QuoteItemConsumer,
  QuoteItemSection,
  RenderableItem,
  RenderableSubItem,
  UpdateableProduct
} from '../../../index'

import { trimGroup } from '../../../groups'
import { trimLineItem } from '../../../line-items'
import { trimSubstrate } from '../../../substrates'
import { filterItem, getQuoteOptions } from '../../../util'
import { filterOrderItems, getItemOrder } from '../util'
import { convertQuoteProduct } from '../util/convert-quote-product'
import getSubstrateAreas from '../util/get-substrate-areas'

/**
 * Get Bid, Options, or Archived list of substrates for a Quote.
 */
export function getItemsBySubstrate(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  keys?: string[]
  showGroups?: boolean
  groupKey?: string
  showLineItems?: boolean
  showValues?: boolean
  hideDimensions?: boolean
}): RenderableItem[] {
  const {
    quote,
    options,
    overrideOptions,
    section,
    consumer,
    keys,
    groupKey,
    showGroups = false,
    hideDimensions
  } = args
  const showLineItems = args.showLineItems === false ? false : true
  const showValues = args.showValues === false ? false : true
  // console.log('items by substarte', { section, consumer, groupKey, showGroups })
  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const substrateOrder = getItemOrder('substrate', section, quote)

  const items = substrateOrder
    .map((orderItem) => {
      if (filterOrderItems({ orderItem, showLineItems, keys })) {
        return null
      }

      if (orderItem.type === 'substrate') {
        const substrate = quote.substrates[orderItem.key]

        // Get areas substrate is in
        const substrateAreas = getSubstrateAreas({
          quote,
          options: quoteOptions,
          clientOptions: options,
          substrateKey: orderItem.key,
          consumer,
          section,
          showValues,
          showGroup: showGroups,
          groupKey,
          hideDimensions
        })

        if (!substrate || !substrateAreas.length) {
          return null
        }

        // Get total of substrateAreas values
        const { subItemTotal, products, areaIds, hours, prep } = substrateAreas.reduce(
          (
            acc: {
              subItemTotal: number
              hours: number
              prep: number
              products: UpdateableProduct[]
              areaIds: string[]
            },
            substrateArea: RenderableSubItem
          ) => {
            const convertedProduct: UpdateableProduct = substrateArea?.additionalFields?.products
              ? convertQuoteProduct({ product: substrateArea.additionalFields.products })
              : null
            return {
              subItemTotal: acc.subItemTotal + Number(substrateArea.value),
              hours: acc.hours + Number(substrateArea.additionalFields?.painting ?? 0),
              prep: acc.prep + Number(substrateArea.additionalFields?.prep ?? 0),
              products: [...acc.products, convertedProduct].filter(Boolean),
              areaIds: [...acc.areaIds, substrateArea?.additionalFields?.areaKey ?? null].filter(Boolean)
            }
          },
          { subItemTotal: 0, hours: 0, prep: 0, products: [], areaIds: [] }
        )

        // Clean substrate for display
        const trimmedSubstrate = trimSubstrate({
          quote,
          item: { ...orderItem, ...substrate },
          subItems: [],
          areaIds,
          options: quoteOptions,
          consumer,
          clientOptions: options,
          section,
          showGroup: showGroups,
          groupKey,
          hours,
          prep,
          products,
          price:
            // Determine correct correct substrate value for section
            filterItem(substrate, section) && section === 'bid' && quote.version < 3
              ? substrate?.totals?.price ?? null
              : subItemTotal
        })

        return trimmedSubstrate
      } else if (orderItem.type === 'line_item') {
        const lineItem = quote.lineItems[orderItem.key]
        if (lineItem && filterItem(lineItem, section)) {
          return trimLineItem({ ...orderItem, ...lineItem }, quoteOptions, consumer, options) as RenderableItem
        }
      } else if (orderItem.type === 'group') {
        const group = quote.groups[orderItem.key]
        if (group && filterItem(group, section)) {
          return trimGroup({ ...orderItem, ...group }, options, quote)
        }
      }

      return null
    })
    .filter((item) => item)

  return items
}
