import type { QuoteArea, QuoteDocument, OptionsDocument, OrderItem, QuoteOptions } from 'paintscout'
import type { RenderableSubItem } from '../../..'
import type { QuoteItemSection, QuoteItemConsumer } from '../../../..'
import type { RenderableItemSubstrate } from '../../../../area-substrates'
import { formatAreaSubstrate } from '../../util/format-area-substrate'
import { getAreaSubstrates } from '../../../../area-substrates'
import { filterItem } from '../../../../util'

// Get areaSubstrates and values for given area/section
export const getAreaSubstrateValues = ({
  area,
  section,
  quote,
  options,
  orderItem,
  quoteOptions,
  consumer,
  showGroups,
  hideDimensions
}: {
  area: QuoteArea
  section: QuoteItemSection
  quote: QuoteDocument
  options?: OptionsDocument
  orderItem: OrderItem
  quoteOptions: QuoteOptions
  consumer: QuoteItemConsumer
  showGroups?: boolean
  hideDimensions?: boolean
}): {
  sectionAreaSubstrates: RenderableItemSubstrate[]
  areaSubstrates: RenderableSubItem[]
  value: number
} => {
  const bidAreaSubstrates = filterItem(area, section)
    ? getAreaSubstrates({
        quote,
        options,
        areaKey: orderItem.key,
        section: 'bid',
        overrideOptions: quoteOptions,
        showGroups
      })
    : []

  const sectionAreaSubstrates =
    section === 'bid'
      ? bidAreaSubstrates
      : getAreaSubstrates({
          quote,
          options,
          areaKey: orderItem.key,
          section,
          overrideOptions: quoteOptions,
          showGroups
        })

  const rawAreaSubstrates = [...bidAreaSubstrates, ...(section === 'bid' ? [] : sectionAreaSubstrates)]

  let value = 0
  const areaSubstrates = rawAreaSubstrates
    .map((substrate) => {
      const formattedAreaSubstrate = formatAreaSubstrate({
        clientOptions: options,
        quote,
        item: substrate,
        options: quoteOptions,
        consumer,
        section,
        hideDimensions
      })
      value += Number(formattedAreaSubstrate.value)
      if (substrate.workOrderOnly) {
        return null
      }
      return formattedAreaSubstrate
    })
    .filter(Boolean)

  return {
    sectionAreaSubstrates,
    areaSubstrates,
    value: quote?.version >= 6 ? Math.round(value * 100) / 100 : value
  }
}
