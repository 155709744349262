import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { getFeatures, getOptionsProviderConfig, getQuoteOptions } from '@paintscout/util/builder'
import type { CollectPaymentDialogProps } from '../CollectPaymentDialog/CollectPaymentDialog'
import CollectPaymentDialog from '../CollectPaymentDialog/CollectPaymentDialog'
import type { OptionsDocument, PayrixPaymentType } from 'paintscout'
import { type QuoteDocument } from 'paintscout'
import CreditCardForm from './CreditCardForm'
import * as Sentry from '@sentry/react'
import ACHForm from './ACHForm'
import { Tab, Tabs } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import round from 'lodash/round'

const useStyles = makeStyles<Theme, CollectPaymentDialogProps>(
  (theme) => ({
    tabsRoot: {
      minHeight: 'unset',
      marginBottom: theme.spacing(2)
    },
    tabRoot: {
      marginRight: 0,
      padding: 0,
      minHeight: 'unset'
    },
    tabWrapper: {
      flexDirection: 'row',
      padding: theme.spacing(2, 0),
      '& svg': {
        marginBottom: '0 !important',
        marginRight: theme.spacing(1),
        fontSize: '1.2rem'
      }
    }
  }),
  { name: 'PayrixPaymentDialog' }
)

export interface FormProps {
  amount: number // dollars including surcharge
  companyId: string
  handlePaymentError: (error: any) => void
  handleSuccessfulPayment: () => void
  merchantId: string
  note: string
  options: OptionsDocument
  payFieldsLoading: boolean
  paymentRequestId: string
  quote: QuoteDocument
  setActivityId: React.Dispatch<React.SetStateAction<string>>
  setPayFieldsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  source: string
  surcharge: number // dollars
}

export default function CollectPayrixPaymentDialog(props: CollectPaymentDialogProps) {
  const classes = useStyles(props)
  const { onCancel, options, quote, isDeposit, source, amount, note, companyId, paymentRequestId } = props
  const { paymentType: propsPaymentType, ...propsToPass } = props // destructuring again to pass paymentType from state and the rest as is

  const config = getOptionsProviderConfig({ options, name: 'payrix' })

  const [paymentType, setPaymentType] = useState<PayrixPaymentType>(
    propsPaymentType === 'both' ? 'credit' : propsPaymentType
  )
  const allowToggle = propsPaymentType === 'both'

  const [payFieldsLoading, setPayFieldsLoading] = useState(true)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [activityId, setActivityId] = useState('')
  const [isCooldown, setIsCooldown] = useState(true)

  const { enqueueSnackbar } = useSnackbar()

  const [submitting, setSubmitting] = useState(false)

  const { surcharge } = getQuoteOptions({ options, quote })
  const surchargeFeature = getFeatures({ options })?.surcharge && paymentType !== 'ach'
  const showSurcharge =
    surchargeFeature &&
    !!surcharge?.enabled &&
    surcharge?.value > 0 &&
    (!isDeposit || (isDeposit && surcharge?.applyToDeposit)) &&
    paymentType === 'credit'
  const roundedSurchargeAmount = showSurcharge ? round((amount * surcharge.value) / 100, 2) : 0
  const roundedAmount = round(amount, 2)

  function handleSuccessfulPayment() {
    setPaymentSuccess(true)
  }

  function handlePaymentError(error: any) {
    Sentry.captureException(error)
    enqueueSnackbar('Something went wrong, please try again later.', { variant: 'error' })
    onCancel()
  }

  const formProps: FormProps = {
    amount: roundedAmount + roundedSurchargeAmount,
    companyId,
    handlePaymentError,
    handleSuccessfulPayment,
    merchantId: config.apiKey,
    note,
    options,
    payFieldsLoading: payFieldsLoading || submitting,
    paymentRequestId,
    quote,
    setActivityId,
    setPayFieldsLoading,
    setSubmitting,
    source,
    surcharge: roundedSurchargeAmount
  }

  const handleTabChange = (value: PayrixPaymentType) => {
    if (payFieldsLoading || paymentType === value) {
      return
    }
    setPayFieldsLoading(true)
    // set a brief timeout to allow the tab to change before the form is reloaded
    setTimeout(() => {
      setPayFieldsLoading(false)
    }, 1000)
  }

  // adds a cooldown prior to tab change or dialog close to allow threat metrics to finishing loading. else it will error if exited prematurely
  useEffect(() => {
    if (payFieldsLoading) setIsCooldown(true)
    if (!payFieldsLoading) {
      setTimeout(() => {
        setIsCooldown(false)
      }, 2000)
    }
  }, [payFieldsLoading])

  return (
    <CollectPaymentDialog
      activityId={activityId}
      payrixSuccess={paymentSuccess}
      loading={payFieldsLoading || submitting}
      isCooldown={isCooldown}
      showSurcharge={showSurcharge}
      provider={'payrix'}
      paymentType={paymentType}
      {...propsToPass}
    >
      <>
        {allowToggle && !payFieldsLoading && (
          <Tabs
            classes={{ root: classes.tabsRoot }}
            value={paymentType}
            onChange={(e, newValue) => setPaymentType(newValue)}
            variant={'fullWidth'}
            style={{ marginTop: '-8px' }}
          >
            <Tab
              classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
              icon={<CreditCardIcon />}
              label={'Credit Card'}
              onClick={() => handleTabChange('credit')}
              value={'credit'}
              disabled={isCooldown}
            />
            <Tab
              classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
              icon={<AccountBalanceOutlinedIcon />}
              label={'ACH'}
              onClick={() => handleTabChange('ach')}
              value={'ach'}
              disabled={isCooldown}
            />
          </Tabs>
        )}
        {paymentType === 'credit' ? <CreditCardForm {...formProps} /> : <ACHForm {...formProps} />}
      </>
    </CollectPaymentDialog>
  )
}
