import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { DropdownSelect, FormSection, Typography } from '../../'
import type { ProviderConfig } from 'paintscout'
import { useProcessEvent, useAdminIntegrationsConfig } from '@ui/core'
import { ProviderFormSection } from './ProviderFormSection'

const useStyles = makeStyles<Theme, SalesforceProviderFormProps>(
  (_theme) => ({
    root: {}
  }),
  { name: 'SalesforceProviderForm' }
)

export interface SalesforceProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
  companyId?: string
  userId?: string
}

export function SalesforceProviderForm(props: SalesforceProviderFormProps) {
  const {
    providerConfig,
    providerName,
    isSettingsIntegration: _isSettingsIntegration = false,
    isAdminIntegrations = false,
    onChange,
    companyId,
    userId
  } = props
  const classes = useStyles(props)
  const { enabled } = providerConfig
  // const { options } = useClientOptions()
  const [loading, setLoading] = useState(false)
  console.log({ providerConfig })
  // const { handleEventChange, handleConfigChange, handleConfigToggleChange } = useMemo(() => {
  //   return integrationEventHandlers({
  //     providerName,
  //     providerConfig,
  //     onChange
  //   })
  // }, [providerName, providerConfig, onChange])

  const { adminIntegrationsConfig } = useAdminIntegrationsConfig()
  const { processEvent } = useProcessEvent()

  useEffect(() => {
    async function load() {
      if (enabled) {
        setLoading(true)
        const configItemsRes = isAdminIntegrations
          ? await adminIntegrationsConfig({
              provider: providerName,
              type: 'get-config-items',
              params: {
                companyId,
                userId
              }
            })
          : await processEvent({
              provider: providerName,
              type: 'get-config-items',
              params: {}
            })
        console.log({ configItemsRes })

        setLoading(false)
      }
    }
    setTimeout(() => {
      load()
    }, 3000)
  }, [enabled])

  const DropdownItemSelection = (props: {
    name: string
    items: Array<{ value: number | string; label: string }>
    searchable?: boolean
  }) => {
    const { name, items, searchable } = props
    const selectedItem = items.filter((item) => item.value === providerConfig.config[name]?.value)[0] ?? null
    return (
      <DropdownSelect
        style={{ alignSelf: 'flex-end' }}
        name={name}
        searchable={searchable}
        disabled={loading}
        value={selectedItem}
        onChange={(option) => handleDropdownSelection(option, name)}
        options={items}
        className={classes.select}
        placeholder={{
          plural: 'Select Item...'
        }}
        variant={'single'}
      />
    )
  }
  const handleDropdownSelection = (option: any, name: string) => {
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: option
      }
    })
  }
  const alternatePhoneNumberTypes = [
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Home', label: 'Home' },
    { value: 'Assistant', label: 'Assistant' },
    { value: 'Other', label: 'Other' }
  ]

  const addressTypes = [
    { value: 'Mailing', label: 'Mailing' },
    { value: 'Other', label: 'Other' }
  ]

  return (
    <>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <ProviderFormSection
          title={'Address Type'}
          subTitle={'Select which Salesmate address value to use as address in PaintScout.'}
          required={
            !providerConfig?.config.addressType?.value ||
            !addressTypes.filter((item) => item.value === providerConfig.config?.totalServiceItem?.value)[0]
          }
          rightContent={
            <div className={classes.itemSelection}>
              <Typography className={classes.itemSelectText}>Use address:</Typography>
              <DropdownItemSelection name={'addressType'} items={addressTypes} />
            </div>
          }
        />
        <ProviderFormSection
          title={'Alternate Phone Number Type'}
          subTitle={'Select which Salesmate phone number value to use as alternate phone number in PaintScout.'}
          required={
            !providerConfig?.config.alternatePhoneNumberType?.value ||
            !alternatePhoneNumberTypes.filter(
              (item) => item.value === providerConfig.config?.totalServiceItem?.value
            )[0]
          }
          rightContent={
            <div className={classes.itemSelection}>
              <Typography className={classes.itemSelectText}>Use phone number:</Typography>
              <DropdownItemSelection name={'alternatePhoneNumberType'} items={alternatePhoneNumberTypes} />
            </div>
          }
        />
      </FormSection>
    </>
  )
}
