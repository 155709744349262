import { makeStyles } from '@material-ui/core'
import type { User } from '@paintscout/api'
import { useSearchUsersQuery } from '@paintscout/api'
import React, { useEffect, useState } from 'react'
import { useClientOptions } from '../ClientOptionsProvider'
import type { DropdownSelectOption, DropdownSelectProps, MultiSelectProps, SingleSelectProps } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'
import Typography from '../Typography'
import { useUser } from '../UserProvider'

interface EstimatorSelectProps extends Omit<DropdownSelectProps, 'value'> {
  value: string | string[]
  userFields?: string[]
  roles?: string[]
  companyId?: string
  omit?: string[]
  onFetched?: (users: DropdownSelectOption[]) => void
}

const useStyles = makeStyles({
  error: {
    '& a': {
      color: 'inherit'
    }
  }
})

export function EstimatorSelect({
  value,
  roles,
  omit,
  variant,
  companyId: companyIdProp,
  userFields,
  onFetched,
  ...props
}: EstimatorSelectProps) {
  const classes = useStyles({})
  const { isSuperadmin } = useUser()
  const clientOptions = useClientOptions()
  const companyId = companyIdProp ?? clientOptions.clientId
  const query = isSuperadmin ? `app_metadata.companyId:"${companyId}" AND app_metadata.active:true` : ''
  const [options, setOptions] = useState<DropdownSelectOption[]>([])
  const { loading, refetch, error } = useSearchUsersQuery({
    variables: {
      query
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const newOptions = mapUsersToOptions(data?.searchUsers?.rows ?? []).filter(
        (option) => !omit?.includes(option.value)
      )
      setOptions(newOptions)
      if (onFetched) {
        onFetched(newOptions)
      }
    }
  })

  // console.log({ companyId, query, data })

  const mapUsersToOptions = (users: User[]) => {
    return [
      ...users
        .filter((user) => user && (!roles || (user.app_metadata?.roles ?? []).some((role) => roles.includes(role))))
        .map((user) => ({
          label: user.name,
          value: user.user_id,
          ...userFields?.reduce((acc, field) => {
            const fieldParts = field.split('.')
            const value = fieldParts.reduce((obj, part) => obj?.[part], user)
            return { ...acc, [fieldParts[fieldParts.length - 1]]: value }
          }, {})
        }))
    ]
  }

  const selectedValues: DropdownSelectOption[] = options.filter((option) =>
    (Array.isArray(value) ? value : [value]).includes(option.value)
  )

  const singleSelectProps = { ...props, value: selectedValues[0], variant: 'single' } as SingleSelectProps
  const multiSelectProps = { ...props, value: selectedValues, variant: 'multi' } as MultiSelectProps

  const selectProps = variant === 'multi' ? multiSelectProps : singleSelectProps

  useEffect(() => {
    if (typeof BroadcastChannel != 'undefined') {
      const channel = new BroadcastChannel('callout')
      const handleMessage = (event: MessageEvent) => {
        if (event.data === 'confirmed-dialog') {
          refetch()
        }
      }
      channel.addEventListener('message', handleMessage)

      return () => {
        channel.removeEventListener('message', handleMessage)
        channel.close()
      }
    }
  }, [])

  return (
    <div>
      <DropdownSelect {...selectProps} disabled={props.disabled || !!error} loading={loading} options={options} />
      {error && (
        <div className={classes.error}>
          <Typography variant="subtitle2">
            Unable to load estimators.{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                refetch()
              }}
            >
              Retry?
            </a>
          </Typography>
        </div>
      )}
    </div>
  )
}

export default EstimatorSelect
