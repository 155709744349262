import { Grid, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { getObjectLabels } from '@paintscout/util/builder'
import type { StyleClasses } from '@ui/core/theme'
import React from 'react'
import CheckboxField from '../CheckboxField'
import { useClientOptions } from '../ClientOptionsProvider'
import FormSection from '../FormSection'
import FormSectionTitle from '../FormSectionTitle'
import Typography from '../Typography'

const useStyles = makeStyles<Theme, CustomPermissionsFormProps>(
  (_theme) => ({
    root: {}
  }),
  { name: 'CustomPermissionsForm' }
)

export interface CustomPermissionsFormProps {
  classes?: StyleClasses<typeof useStyles>
}

/*
      write:all-accepted-quotes - exclude from admin
      
      delete:all-quotes - exclude from admin
      
      create:contacts - exclude from admin, exclude from sales
      change-status-draft:all-quotes - exlude-from admin
      write:account-settings-integrations - exclude from admin
  
      write:assigned-accepted-quotes - exclude from sales
      delete:assigned-quotes - exclude from sales
      change-status-draft:assigned-quotes - exclude from sales
  
    */

function CustomPermissionsForm(props: CustomPermissionsFormProps) {
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  return (
    <>
      <FormSectionTitle title={'Custom Permissions'} classes={{ root: classes.title }} variant="h2" />
      <FormSection>
        <FormSectionTitle
          variant="h3"
          title={'Admin'}
          subTitle={`Admin users can always see and edit all ${objectLabels.quote.plural.toLowerCase()}. They can also update all Settings. They can do everything in PaintScout.`}
        />
        <Grid item xs={12}>
          <CheckboxField
            type="checkbox"
            InputProps={{
              checked: true
            }}
            name=""
            label={`Full Access`}
            sublabel={`Admin users always have full access to the complete functionality of PaintScout.`}
          />
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle
          variant="h3"
          title={'Sales'}
          subTitle={`Sales users can create & edit their own ${objectLabels.quote.plural.toLowerCase()}. They can also update their own profile, but have no access to Account Settings.`}
        />
        <Typography variant="h6" gutterBottom={true}>
          Additional Permissions
        </Typography>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="read:all-quotes"
              name="options.options.permissions.sales.include"
              label={`View All ${objectLabels.quote.plural}`}
              sublabel={`Let sales users view all ${objectLabels.quote.plural.toLowerCase()} (${objectLabels.quote.plural.toLowerCase()} from other users).`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="read:all-invoices"
              name="options.options.permissions.sales.include"
              label={`View All Invoices`}
              sublabel={`Let sales users view all invoices (invoices from other users).`}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle
          variant="h3"
          title={'Crew'}
          subTitle={`Crew users can view and add additional work to existing ${objectLabels.quote.plural.toLowerCase()} & invoices that have been assigned to them.`}
        />
        <Typography variant="h6" gutterBottom={true}>
          Additional Permissions
        </Typography>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="send:assigned-quotes"
              name="options.options.permissions.crew.include"
              label={`Send Assigned ${objectLabels.quote.plural}`}
              sublabel={`Let crew users send ${objectLabels.quote.plural.toLowerCase()}, in addition to viewing them.`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="send:assigned-invoices"
              name="options.options.permissions.crew.include"
              label={`Send Assigned Invoices`}
              sublabel={`Let crew users send Invoices, in addition to viewing them.`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="read:all-quotes"
              name="options.options.permissions.crew.include"
              label={`View All ${objectLabels.quote.plural}`}
              sublabel={`Let crew users view all ${objectLabels.quote.plural.toLowerCase()} (${objectLabels.quote.plural.toLowerCase()} from other users).`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="read:all-invoices"
              name="options.options.permissions.crew.include"
              label={`View All Invoices`}
              sublabel={`Let crew users view all invoices (not just those that are assigned).`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="additional-work:all-quotes"
              name="options.options.permissions.crew.include"
              label={`Add Additional Work to All ${objectLabels.quote.plural}`}
              sublabel={`Let crew users add additional work to all ${objectLabels.quote.plural.toLowerCase()} (even if they haven't been assigned).`}
            />
          </Grid>
        </Grid>
        {/* <Typography variant="h6" gutterBottom={true}>
      Exclude Permissions
    </Typography>
    <Grid item xs={12}>
      <Grid item xs={12}>
        <CheckboxField
          type="checkbox"
          value="read:assigned-invoices"
          name="options.options.permissions.crew.exclude"
          label={`View No Invoices`}
          sublabel={`Prevent crew users from viewing any invoices.`}
        />
      </Grid>
    </Grid> */}
      </FormSection>

      <FormSection>
        <FormSectionTitle
          variant="h3"
          title={'Viewer'}
          subTitle={`Viewers have read-only access to all ${objectLabels.quote.plural.toLowerCase()}.`}
        />
        <Typography variant="h6" gutterBottom={true}>
          Additional Permissions
        </Typography>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="send:all-quotes"
              name="options.options.permissions.viewer.include"
              label={`Send All ${objectLabels.quote.plural}`}
              sublabel={`Let viewer users send ${objectLabels.quote.plural.toLowerCase()}, in addition to viewing them.`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="send:all-invoices"
              name="options.options.permissions.viewer.include"
              label={`Send All Invoices`}
              sublabel={`Let viewer users send invoices, in addition to viewing them.`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="checkbox"
              value="write-presentation-section:all-quotes"
              name="options.options.permissions.viewer.include"
              label={`Write Presentation Section on all ${objectLabels.quote.plural}`}
              sublabel={`Let viewers edit presentations on all ${objectLabels.quote.plural.toLowerCase()}.`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            type="checkbox"
            value="write-presentation-section:all-quotes"
            name="options.options.permissions.viewer.include"
            label={`Write Presentation Section on all ${objectLabels.quote.plural}`}
            sublabel={`Let viewers edit presentations on all ${objectLabels.quote.plural.toLowerCase()}.`}
          />
        </Grid>
      </FormSection>
    </>
  )
}

export default CustomPermissionsForm
