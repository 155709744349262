import { makeStyles } from '@material-ui/core'
import { Typography } from '@ui/paintscout'
import React from 'react'
import { formatCurrency, getFeatures, getQuoteOptions } from '@paintscout/util/builder'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import round from 'lodash/round'

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.h3,
    whiteSpace: 'nowrap',
    '& span': {
      color: theme.palette.text.disabled
    }
  }
}))

export interface PaymentDialogRightContentProps {
  quote: QuoteDocument
  options: OptionsDocument
  paymentAmount: number
  includeSurcharge?: boolean
  showCurrency?: boolean
}

export default function PaymentDialogRightContent({
  quote,
  options,
  paymentAmount,
  includeSurcharge,
  showCurrency
}: PaymentDialogRightContentProps) {
  const classes = useStyles()
  const { surcharge } = getQuoteOptions({ options, quote })
  const surchargeFeature = getFeatures({ options })?.surcharge
  const currency = options.options.currency || 'USD'

  const balanceDue = quote?.totals?.balance_due

  const surchargeAmount =
    includeSurcharge && !!surchargeFeature && !!surcharge?.enabled && surcharge?.value > 0
      ? round((paymentAmount * surcharge.value) / 100, 2)
      : 0
  const roundedAmount = round(paymentAmount, 2)

  const formattedAmount = formatCurrency({ options, value: roundedAmount + surchargeAmount })
  const formattedBalanceDue = balanceDue ? formatCurrency({ options, value: balanceDue + surchargeAmount }) : null
  return (
    <Typography className={classes.root} align={'center'}>
      {formattedAmount} {!!formattedBalanceDue && <span>of {formattedBalanceDue}</span>}
      {showCurrency && <span> {currency}</span>}
    </Typography>
  )
}
