import { Card, CardContent, Grid } from '@material-ui/core'
import type { User } from '@paintscout/api'
import { getHighestRole } from '@paintscout/util'
import {
  Button,
  ConfirmationDialog,
  FloatingMenu,
  FormSection,
  FormSectionTitle,
  FormikCheckbox,
  FormikInputField,
  MenuItem,
  Select,
  Typography,
  useDialogs
} from '@ui/paintscout'
import { DeactivateUserDialog } from '@ui/react-quote/dialogs'
import { Field, FieldArray, useFormikContext } from 'formik'
import type { OptionsDocument, UserPreferencesDocument } from 'paintscout'
import { default as React, useEffect, useState } from 'react'
import UserImportExport from './UserImportExport'
import { useAuth } from 'react-auth0'

export interface UserAdministrationFormProps {
  section?: string

  onSectionChange?: (section: string) => any
}

export default function UserAdministrationForm({ section = 'general', onSectionChange }: UserAdministrationFormProps) {
  const [menuValue, setMenuValue] = useState(section)

  // update if url changes
  useEffect(() => {
    setMenuValue(section)
  }, [section])

  function handleMenuItemClick(item) {
    if (onSectionChange) {
      onSectionChange(item.key)
    }
    setMenuValue(item.key)
  }

  const menuItems = [
    { key: 'general', label: 'General' },
    { key: 'gamification', label: 'Gamification' },
    { key: 'importExport', label: 'Import/Export' }
  ]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={2}>
        <FloatingMenu
          value={menuValue}
          defaultValue="general"
          items={menuItems}
          onItemClick={handleMenuItemClick}
          collapseOn="lg"
        />
      </Grid>
      <Grid item xs={12} xl={10}>
        <Card>
          <CardContent>
            {menuValue === 'general' && <GeneralForm />}
            {menuValue === 'gamification' && <GamificationForm />}
            {menuValue === 'importExport' && <UserImportExport />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

function GamificationForm() {
  const { values } = useFormikContext<{
    options: OptionsDocument
    preferences: UserPreferencesDocument
    user: User
  }>()

  console.log({ values })

  return (
    <>
      <FormSectionTitle title="Leaderboards" />
      <FormSection>
        <Grid item>
          <Field
            component={FormikCheckbox}
            name="user.user_metadata.leaderboardOptOut"
            label={`Remove completely from leaderboards`}
            toolTip={'The user will not see or be included in any leaderboards.'}
          />
          <Typography variant="body2">
            Users can be excluded from the leaderboard with this checkbox. They will still see gamification
            functionality & leaderboards, but their stats will not be ranked. Typically this would be used for "banning"
            users from the leaderboards for various reasons.
          </Typography>
        </Grid>
      </FormSection>
    </>
  )
}

function GeneralForm() {
  const { openDialog, dismissDialog } = useDialogs()
  const { values, setFieldValue, submitForm } = useFormikContext<{
    options: OptionsDocument
    preferences: UserPreferencesDocument
    user: User
  }>()
  const { session } = useAuth()
  const isSuperadmin = session?.app_metadata.roles.includes('superadmin')
  const isViewingSelf = session?.user_id === values.user.user_id

  console.log({ values, isSuperadmin, isViewingSelf })

  return (
    <>
      <FormSectionTitle title="General" />
      <FormSection>
        <Grid container spacing={2} style={{ paddingBottom: '5px' }}>
          <Grid item xs={12}>
            <Typography variant="body1">
              User is currently: {values.user.app_metadata.active ? 'Active' : 'Inactive'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                if (values.user.app_metadata.active) {
                  openDialog(ConfirmationDialog, {
                    title: 'Deactivate User',
                    message:
                      'Are you sure you want to deactivate this user? Clients chargebee subscription will be updated to exclue user if they are a billing client.',
                    scary: true,
                    onCancel: dismissDialog,
                    onConfirm: () => {
                      dismissDialog()
                      openDialog(DeactivateUserDialog, {
                        user: values.user,
                        onClose: dismissDialog,
                        onConfirm: () => {
                          window.location.reload()
                        }
                      })
                    }
                  })
                } else {
                  openDialog(ConfirmationDialog, {
                    title: 'Activate User',
                    message:
                      'Are you sure you want to activate this user? Clients chargebee subscription will be updated to include new user if they are a billing client.',
                    scary: true,
                    onCancel: dismissDialog,
                    onConfirm: () => {
                      dismissDialog()
                      setFieldValue('user.app_metadata.active', true)
                      submitForm()
                    }
                  })
                }
              }}
            >
              {values.user.app_metadata.active ? 'Deactivate' : 'Activate'}
            </Button>
          </Grid>
        </Grid>
      </FormSection>

      <FormSectionTitle title="Role" />
      <FormSection>
        <Grid item>
          <FieldArray
            name="user.app_metadata.roles"
            render={(arrayHelpers) => (
              <Select
                margin="dense"
                disabled={!isSuperadmin && isViewingSelf}
                value={getHighestRole(arrayHelpers.form.values.user.app_metadata.roles)}
                variant="outlined"
                onChange={(e) => {
                  arrayHelpers.form.setFieldValue('user.app_metadata.roles', [e.target.value])
                }}
              >
                <MenuItem value="support">Support</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="sales">Sales</MenuItem>
                <MenuItem value="crew">Crew</MenuItem>
                <MenuItem value="viewer">Viewer</MenuItem>
                <MenuItem value="corporate">Corporate</MenuItem>
              </Select>
            )}
          />
        </Grid>
      </FormSection>

      <FormSectionTitle title="Automation" />
      <FormSection>
        <Grid item>
          <Field
            component={FormikInputField}
            name="user.user_metadata.salesmateId"
            label={`Salesmate ID`}
            toolTip={"The Salesmate ID is the 4 or 5 digit number in a contact's Salesmate URL: /app/contacts/{id}/"}
          />
        </Grid>
      </FormSection>
    </>
  )
}
