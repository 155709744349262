import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import ActivityTitle from '../ActivityTitle'
import ActivityFooter from '../ActivityFooter'
import ActivityContent from '../ActivityContent'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'

import ActivityOwner from '../ActivityOwner'
import QuoteLabel from '../QuoteLabel'
import type { PaymentActivityDetails } from '@paintscout/api'

const useStyles = makeStyles<Theme, ActivityTypeComponentProps>((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    '& li': {
      ...theme.typography.body1,
      textAlign: 'left',
      marginBottom: theme.spacing(0.25)
    }
  },
  event: {
    color: theme.palette.text.secondary
  }
}))

export default function QuotePaymentActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails, showContent } = props
  const classes = useStyles(props)
  const { provider, note, isProcessing, isFailed } = activity.details as PaymentActivityDetails

  const paymentStatusTitles: { [key: string]: string } = {
    processing: 'Payment Processing',
    failed: 'Failed Payment',
    default: 'Successful Payment'
  }

  let title: string

  if (provider === 'wisetack') {
    title = 'Successful Financing'
  } else {
    switch (true) {
      case isProcessing:
        title = paymentStatusTitles.processing
        break
      case isFailed:
        title = paymentStatusTitles.failed
        break
      default:
        title = paymentStatusTitles.default
    }
  }

  return (
    <>
      <ActivityTitle>
        <>
          {!!provider && (
            <>
              <Highlight>{title}</Highlight>
            </>
          )}
          {!provider && (
            <>
              {showDocTypeDetails ? (
                <>
                  <QuoteLabel activity={activity} /> <Highlight>Payment</Highlight> Added
                  <ActivityOwner activity={activity} />
                </>
              ) : (
                <>
                  <Highlight>Payment</Highlight> Added
                </>
              )}
            </>
          )}
        </>
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {showContent && !!note && <ActivityContent className={classes.content}>{note}</ActivityContent>}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}
