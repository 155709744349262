/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { QuoteTypeOption } from '../../index'

export function reorderQuoteTypeOptions(args: {
  options: OptionsDocument
  quoteTypeOptions: QuoteTypeOption[]
}): OptionsDocument {
  const { options, quoteTypeOptions } = args

  const order = quoteTypeOptions.map((item: QuoteTypeOption) => item.key)
  if (!order.includes('all')) {
    order.unshift('all')
  }

  return {
    ...options,
    options: {
      ...options.options,
      intExtOptions: {
        ...options.options.intExtOptions,
        order
      }
    }
  }
}
