import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Typography } from '../../'
import { getFeature, getFeatures, getOptionsProviderConfig, updatePldConfig } from '@paintscout/util/builder'
import SettingsPage from '../SettingsPage'
import {
  BaseAdminProvider,
  DropboxProviderForm,
  FreshbooksProviderForm,
  HubspotProviderForm,
  PipelineDealsProviderForm,
  QuickBooksProviderForm,
  SageProviderForm,
  XeroProviderForm,
  ZenDeskProviderForm,
  WorkGlueProviderForm,
  ServiceTitanProviderForm,
  SalesforceProviderForm
} from './Integrations'
import { Form, useFormikContext } from 'formik'
import type { OptionsDocument, ProviderConfig, RatesDocument } from 'paintscout'
import debounce from 'lodash/debounce'

const useStyles = makeStyles<Theme, ClientAdminIntegrationsFormProps>(
  (_theme) => ({
    root: {}
  }),
  { name: 'ClientAdminIntegrationsForm' }
)

export interface ClientAdminIntegrationsFormProps {
  classes?: StyleClasses<typeof useStyles>
}

function ClientAdminIntegrationsForm(props: ClientAdminIntegrationsFormProps) {
  const classes = useStyles(props)
  const { values, setValues, handleSubmit } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const { integrations } = getFeatures({ options })
  const providers = integrations && integrations.providers ? integrations.providers : {}
  const { wisetackSignup } = options.options
  const [_loadingProvider, setLoadingProvider] = useState<string>('')
  const location = useLocation()
  const companyId = location.pathname.split('/')[2]

  const hasSettingsIntegrations = getFeature({ options, path: 'settingsIntegrations.enabled' })
  const companyCamProviderConfig = getOptionsProviderConfig({ options, name: 'companycam' })
  const dropboxProviderConfig = getOptionsProviderConfig({ options, name: 'dropbox' })
  const pldProviderConfig = getOptionsProviderConfig({ options, name: 'pld' })
  const quickbooksProviderConfig = getOptionsProviderConfig({ options, name: 'intuit' })
  const hubspotProviderConfig = getOptionsProviderConfig({ options, name: 'hubspot' })
  const sageProviderConfig = getOptionsProviderConfig({ options, name: 'sage' })
  const freshbooksProviderConfig = getOptionsProviderConfig({ options, name: 'freshbooks' })
  const wisetackProviderConfig = getOptionsProviderConfig({ options, name: 'wisetack' })
  const workglueProviderConfig = getOptionsProviderConfig({ options, name: 'workglue' })
  const xeroProviderConfig = getOptionsProviderConfig({ options, name: 'xero' })
  const zenDeskProviderConfig = getOptionsProviderConfig({ options, name: 'getbase' })
  const serviceTitanProviderConfig = getOptionsProviderConfig({ options, name: 'serviceTitan' })
  const salesforceProviderConfig = getOptionsProviderConfig({ options, name: 'salesforce' })

  const updateProvider = debounce(function (providerName) {
    setLoadingProvider(providerName)
    handleSubmit()
    setLoadingProvider('')
  }, 2000)

  return (
    <SettingsPage title="Integrations">
      {!hasSettingsIntegrations && (
        <Typography variant="h6" color="error">
          Client currently using Profile Integrations
        </Typography>
      )}
      <Form className={classes.root}>
        <BaseAdminProvider title={'CompanyCam'} providerConfig={companyCamProviderConfig} />
        <BaseAdminProvider title={'DropBox'} providerConfig={dropboxProviderConfig}>
          <DropboxProviderForm
            providerName="dropbox"
            isAdminIntegrations
            providerConfig={dropboxProviderConfig}
            onChange={handleChange}
            isSettingsIntegration
          />
        </BaseAdminProvider>
        {providers.freshbooks && (
          <BaseAdminProvider title={'FreshBooks'} providerConfig={dropboxProviderConfig}>
            <FreshbooksProviderForm
              providerName="freshbooks"
              isAdminIntegrations
              providerConfig={freshbooksProviderConfig}
              onChange={handleChange}
              isSettingsIntegration
            />
          </BaseAdminProvider>
        )}
        {providers.hubspot && (
          <BaseAdminProvider title={'Hubspot'} providerConfig={hubspotProviderConfig}>
            <HubspotProviderForm
              providerName="hubspot"
              isAdminIntegrations
              providerConfig={hubspotProviderConfig}
              onChange={handleChange}
              isSettingsIntegration
            />
          </BaseAdminProvider>
        )}
        <BaseAdminProvider title="Pipeline CRM" providerConfig={pldProviderConfig}>
          <PipelineDealsProviderForm
            providerName="pld"
            isAdminIntegrations
            companyId={companyId}
            providerConfig={pldProviderConfig}
            onChange={handleChange}
          />
        </BaseAdminProvider>
        {providers.sage && (
          <BaseAdminProvider title="Sage" providerConfig={sageProviderConfig}>
            <SageProviderForm
              isSettingsIntegration
              providerName="sage"
              isAdminIntegrations
              companyId={companyId}
              providerConfig={sageProviderConfig}
              onChange={handleChange}
            />
          </BaseAdminProvider>
        )}
        {providers.salesforce && (
          <BaseAdminProvider title="Salesforce" providerConfig={salesforceProviderConfig}>
            <SalesforceProviderForm
              isSettingsIntegration
              providerName="salesforce"
              isAdminIntegrations
              companyId={companyId}
              providerConfig={salesforceProviderConfig}
              onChange={handleChange}
            />
          </BaseAdminProvider>
        )}
        {providers.stripe && (
          <BaseAdminProvider title={'Stripe'} providerConfig={{ enabled: true } as any}>
            <Typography variant="body1">Settings found in client admin-settings payments section</Typography>
          </BaseAdminProvider>
        )}
        <BaseAdminProvider title={'QuickBooks'} providerConfig={quickbooksProviderConfig}>
          <QuickBooksProviderForm
            isSettingsIntegration
            providerName="intuit"
            isAdminIntegrations
            companyId={companyId}
            providerConfig={quickbooksProviderConfig}
            onChange={handleChange}
          />
        </BaseAdminProvider>
        {providers.wisetack && (
          <BaseAdminProvider title={'Wisetack'} providerConfig={wisetackProviderConfig}>
            <Typography variant="body1">Wisetack application status: {wisetackSignup?.status ?? 'unknown'}</Typography>
            <Typography variant="body1">Client signup link: {wisetackSignup?.signupLink ?? 'unknown'}</Typography>
          </BaseAdminProvider>
        )}
        <BaseAdminProvider title={'Workglue'} providerConfig={workglueProviderConfig}>
          <WorkGlueProviderForm
            providerName="workglue"
            isAdminIntegrations
            isSettingsIntegration
            companyId={companyId}
            providerConfig={workglueProviderConfig}
            onChange={handleChange}
          />
        </BaseAdminProvider>
        {providers.xero && (
          <BaseAdminProvider title={'Xero'} providerConfig={xeroProviderConfig}>
            <XeroProviderForm
              providerName="xero"
              isAdminIntegrations
              isSettingsIntegration
              providerConfig={xeroProviderConfig}
              onChange={handleChange}
            />
          </BaseAdminProvider>
        )}
        {providers.getbase && (
          <BaseAdminProvider title={'Zendesk'} providerConfig={{ ...zenDeskProviderConfig, enabled: true }}>
            <ZenDeskProviderForm
              providerName="getbase"
              isAdminIntegrations
              isSettingsIntegration
              companyId={companyId}
              providerConfig={{ ...zenDeskProviderConfig, enabled: true }}
              onChange={handleChange}
            />
          </BaseAdminProvider>
        )}
        {providers.serviceTitan && (
          <BaseAdminProvider title={'ServiceTitan'} providerConfig={serviceTitanProviderConfig}>
            <ServiceTitanProviderForm
              providerName="serviceTitan"
              isAdminIntegrations
              isSettingsIntegration
              companyId={companyId}
              providerConfig={serviceTitanProviderConfig}
              onChange={handleChange}
            />
          </BaseAdminProvider>
        )}
      </Form>
    </SettingsPage>
  )

  // Translate the change in providerConfig to PipelineOption
  function handlePLDChange(providerConfig: ProviderConfig) {
    const newOptionsValues = updatePldConfig(providerConfig, options)

    const newValues = {
      ...values,
      options: {
        ...newOptionsValues
      }
    }

    setValues(newValues)
  }

  function handleChange(providerName: string, providerConfig: ProviderConfig, save: boolean) {
    if (providerName === 'pld') {
      handlePLDChange(providerConfig)
    } else {
      setValues({
        ...values,
        options: {
          ...values.options,
          options: {
            ...values.options.options,
            integrations: {
              ...values.options.options.integrations,
              [providerName]: {
                ...providerConfig
              }
            }
          }
        }
      })
    }

    if (save) {
      updateProvider(providerName)
    }
  }
}

export default ClientAdminIntegrationsForm
