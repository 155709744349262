import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

export const usePayrixStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: theme.spacing(1)
    },
    inputLabel: {
      marginBottom: '5px'
    },
    payField: {
      height: 91,
      overflow: 'hidden'
    }
  }),
  { name: 'PayrixPaymentDialog' }
)
