import type { MultiSelectProps, DropdownSelectProps, SingleSelectProps } from '@ui/paintscout'
import { getFeature, getObjectLabels, getQuoteTypeOptions } from '@paintscout/util/builder'
import { DropdownSelect, useClientOptions } from '@ui/paintscout'
import type { DropdownSelectOption } from '@ui/paintscout'
import React from 'react'

export interface QuoteTypeSelectProps extends Omit<DropdownSelectProps, 'value' | 'options' | 'onChange'> {
  value: string[]
  hideAll?: boolean
  /**
   * Label to use for "all" quote type. Set to falsey to fallback on label in options
   */
  allLabel?: string
  onChange?: (value: string[]) => any
}

export default function QuoteTypeSelect({ value, allLabel, onChange, ...props }: QuoteTypeSelectProps) {
  const { options } = useClientOptions()
  const { quote: quoteLabel } = getObjectLabels({ options })
  const hideAll = getFeature({ options, path: 'quotes.hideAllQuoteType' }) || props.hideAll
  const typeOptions = getQuoteTypeOptions({ options, hideAll })
    .map((type) => ({
      value: type.key,
      label: type.key === 'all' && allLabel ? allLabel : type.label,
      forceTop: type.key === 'all'
    }))
    .sort((a) => (a.value === 'all' ? -1 : 1))
  const allOptionExists = typeOptions.some((typeOption) => typeOption.value === 'all')

  const singleSelectProps = {
    ...props,
    value: typeOptions.find((opt) => value.includes(opt.value)),
    onChange: (option: DropdownSelectOption) => {
      onChange?.([option.value])
    },
    variant: 'single',
    options: typeOptions
  } as SingleSelectProps

  const multiSelectProps = {
    ...props,
    value: typeOptions.filter((opt) => value.includes(opt.value)),
    onChange: (options: DropdownSelectOption[]) => {
      onChange?.(options.some((option) => option.value === 'all') ? ['all'] : options.map((option) => option.value))
    },
    variant: 'multi',
    options: typeOptions.map((option) => ({
      ...option,
      disabled: value.includes('all') && option.value !== 'all' && !hideAll && allOptionExists
    }))
  } as MultiSelectProps

  const selectProps = props.variant === 'multi' ? multiSelectProps : singleSelectProps

  return (
    <DropdownSelect
      placeholder={{ selectPlaceholder: `All ${quoteLabel.value.toLowerCase()} types` }}
      hideSelectAll={allOptionExists}
      {...selectProps}
    />
  )
}
