const defaultCharset = 'abcdefghijklmnopqrstuvwxyz'

export function randomString(args?: { length?: number; charset?: string }): string {
  const charset = args?.charset ? args.charset : defaultCharset
  const length = args?.length ?? 16

  let r = ''
  for (let i = 0; i < length; i++) {
    r += charset.charAt(Math.floor(Math.random() * charset.length))
  }
  return r
}
