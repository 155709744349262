import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'

import { getJobAddress, getJobIdentifier, getQuoteOptions } from '@paintscout/util/builder'

import ContactCard from '../ContactCard'
import SecondaryContactCard from '../SecondaryContactCard'
import JobAddressCard from '../JobAddressCard'
import QuoteDateCard from '../QuoteDateCard/QuoteDateCard'
import QuoteStatusCard from '../QuoteStatusCard'
import QuoteSignCard from '../QuoteSignCard'
import EstimatorCard from '../EstimatorCard'
import CompanyCard from '../CompanyCard'
import QuoteIDCard from '../QuoteIDCard'

import { useQuote } from '../../../context'
import { useClientOptions, Grid } from '@ui/paintscout'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4)
    },

    topRow: {
      marginBottom: theme.spacing(4)
    },

    companyCardWrapper: {
      minWidth: '33.333333%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '50%'
      }
    },
    statusCartWrapper: {
      flexGrow: 1
    },

    signature: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: 180,
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(1)}px auto`
    }
  })

export interface QuoteHeaderProps extends WithStyles<typeof styles> {
  isEditable?: boolean
  variant?: 'default' | 'work-order' | 'product-order-form'
}

function QuoteHeaderFull({ classes, variant }: QuoteHeaderProps) {
  const { quote, isEditable } = useQuote()
  const { options } = useClientOptions()

  const isTemplate = quote.type === 'quoteTemplate'

  const jobAddress = getJobAddress({ quote })
  const jobIdentifier = getJobIdentifier({ quote })

  const { alwaysShowJobAddress, showTopSignature } = getQuoteOptions({ options, quote })
  const hideWorkOrderClientContactInfo = options.options.quotes?.hideWorkOrderClientContactInfo

  const showJobAddress = isEditable || jobAddress || jobIdentifier || alwaysShowJobAddress
  const showSignature = quote.status.value === 'accepted' && variant === 'default' && showTopSignature
  const showContactDetails = !(hideWorkOrderClientContactInfo && variant === 'work-order')

  return (
    <div className={classes.root} id="quote-header-full">
      <Grid container={true} spacing={3} justifyContent="flex-start" classes={{ container: classes.topRow }}>
        <Grid item id={'quote-header-company-card'} classes={{ root: classes.companyCardWrapper }}>
          <CompanyCard />
        </Grid>
        <Grid item id={'quote-header-estimator-card'}>
          <EstimatorCard />
        </Grid>

        <Grid item id={'quote-header-status-card'} classes={{ root: classes.statusCartWrapper }}>
          <QuoteStatusCard variant={variant} />
          {showSignature && <QuoteSignCard dense={true} classes={{ root: classes.signature }} />}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} id={'quote-header-contact-card'}>
          {!isTemplate && showContactDetails && (
            <>
              <ContactCard />
              {quote.secondaryContact && <SecondaryContactCard />}
            </>
          )}
        </Grid>
        <Grid item md={4} id={'quote-header-job-address-card'}>
          {showJobAddress && <JobAddressCard />}
        </Grid>
        <Grid item md={2} id={'quote-header-number-card'}>
          {!isTemplate && <QuoteIDCard />}
        </Grid>
        <Grid item md={2} id={'quote-header-date-card'}>
          {!isTemplate && <QuoteDateCard />}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(QuoteHeaderFull)
