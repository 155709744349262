import React, { useEffect } from 'react'
import type { DialogProps } from '@ui/paintscout'
import { useDialogs } from '@ui/core'
import {
  Button,
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  getPresentationContentStyles,
  Grid,
  HtmlContent,
  Typography,
  useClientOptions
} from '@ui/paintscout'
import { makeStyles } from '@material-ui/core/styles'

import type { QuoteDocument, OptionsDocument } from 'paintscout'

import { useSnackbar } from 'notistack'

import { formatCurrency, getQuoteOptions, getTerms } from '@paintscout/util/builder'
import * as Sentry from '@sentry/core'

import LockIcon from '@material-ui/icons/Lock'
import PaymentDialogRightContent from '../../PaymentDialogRightContent'
import TotalTable from '../../TotalTable'
import PaymentProcessingDialog from './PaymentProcessingDialog'
import type { PaymentIntentResult } from '@stripe/stripe-js'
import round from 'lodash/round'

export interface CollectPaymentDialogProps extends DialogProps {
  quote: QuoteDocument
  options: OptionsDocument
  companyId: string
  amount: number
  activityId?: string
  note?: string
  paymentRequestId?: string
  source?: 'collect-payment' | 'customer-view' | 'preview' | 'payment-request'
  checkForMismatch?: boolean
  isDeposit?: boolean
  showSurcharge?: boolean
  backOnCancel?: boolean
  loading?: boolean
  isCooldown?: boolean
  children?: JSX.Element
  payrixSuccess?: boolean
  paymentType?: 'credit' | 'ach' | 'both'
  provider?: 'stripe' | 'payrix'
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  onCancel: () => void
  onSubmitPayment?: () => Promise<PaymentIntentResult>
  onConfirm?: (updatedQuote?: QuoteDocument) => void | Promise<void>
}

const useStyles = makeStyles((theme) => ({
  root: {},
  slate: {
    minHeight: '10em'
  },
  dialogContent: {
    // overflow: 'visible',
    padding: theme.spacing(1, 2)
  },
  successCheckmark: {
    color: theme.palette.success.main,
    width: '3em',
    height: '3em'
  },
  checkContainer: {
    height: '100%'
  },
  overflowVisible: {
    overflow: 'visible'
  },
  termsContent: {
    ...getPresentationContentStyles(theme, { noMargins: ['li'] })
  },
  termsDialogContent: {
    '&$termsDialogContent': {
      display: 'block'
    },
    '& > div': {
      textAlign: 'left'
    }
  },
  totalTableWrapper: {
    borderRadius: 2
  }
}))

function CollectPaymentDialog({
  onCancel,
  onConfirm,
  onSubmitPayment,
  quote,
  loading,
  setLoading,
  isCooldown,
  activityId,
  amount,
  isDeposit,
  showSurcharge,
  backOnCancel,
  children,
  payrixSuccess,
  provider,
  paymentType,
  ...otherProps
}: CollectPaymentDialogProps) {
  const classes = useStyles()
  const options = useClientOptions()?.options || otherProps.options
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissDialog } = useDialogs()
  const { surcharge } = getQuoteOptions({ options, quote })

  const roundedSurchargeAmount = showSurcharge ? round((amount * surcharge.value) / 100, 2) : 0
  const roundedAmount = round(amount, 2)
  const paymentAmountAfterSurcharge = formatCurrency({ options, value: roundedAmount + roundedSurchargeAmount })
  const isStripe = provider === 'stripe'
  const isPayrix = provider === 'payrix'
  const isPayrixCredit = paymentType === 'credit' && isPayrix

  const leftButton = (
    <Button disabled={loading || isCooldown} onClick={onCancel} variant={'text'}>
      {backOnCancel ? '< Back' : 'Cancel'}
    </Button>
  )

  const Title = () => {
    return (
      <DialogTitle
        fade={false}
        rightContent={
          <PaymentDialogRightContent
            quote={quote}
            options={options}
            includeSurcharge={showSurcharge}
            paymentAmount={roundedAmount}
            showCurrency={isPayrix}
          />
        }
      >{`Pay ${isDeposit ? 'Deposit ' : ''}`}</DialogTitle>
    )
  }

  useEffect(() => {
    if (payrixSuccess) {
      handleSuccessfulPayment()
    }
  }, [payrixSuccess])

  return (
    <Dialog
      onClose={onCancel}
      fullWidth={true}
      maxWidth={'md'}
      classes={{ paper: classes.overflowVisible }}
      {...otherProps}
    >
      <Title />
      <DialogContent
        classes={{ root: classes.dialogContent }}
        style={{ paddingBottom: showSurcharge ? 24 : paymentType === 'ach' ? 24 : 12 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {children}
          </Grid>
          {isPayrixCredit && !loading && (
            <Grid item sm={showSurcharge ? 4 : 5} xs={12}>
              <Typography variant={'caption'} color={'textSecondary'} component={'div'}>
                Available payment methods:
              </Typography>
              <img
                src={
                  'https://res.cloudinary.com/taptapquote/image/upload/w_360/v1738885327/paintscout/payment-methods.png'
                }
                alt={'Payment methods'}
                style={{ marginTop: 8, width: 180 }}
              />
            </Grid>
          )}
          {showSurcharge && !loading && (
            <Grid item sm={isStripe ? 12 : 8} xs={12}>
              <TotalTable
                classes={{ tableWrapper: classes.totalTableWrapper }}
                propQuote={quote}
                propOptions={options}
                propTotals={{
                  subTotal: roundedAmount,
                  balanceDue: roundedAmount + roundedSurchargeAmount,
                  showBalanceDue: true,
                  serviceFees: {
                    surcharge: roundedSurchargeAmount
                  }
                }}
                hideTypeGroups={true}
                showPaid={false}
              />
            </Grid>
          )}
          {isPayrix && !loading && (
            <Grid item sm={showSurcharge ? 12 : 7} xs={12} style={{ textAlign: 'right', marginLeft: 'auto' }}>
              <Typography variant={'caption'} color={'textSecondary'} component={'div'}>
                By clicking Pay, you confirm that you have read and accept our{' '}
                <span
                  onClick={() => openTermsDialog()}
                  style={{ textDecoration: 'underline', cursor: 'pointer', color: 'black' }}
                >
                  Terms & Conditions
                </span>
                {paymentType === 'ach'
                  ? ' and authorize an ACH or electronic debit from your account as detailed above. You also agree that ACH transactions you authorize comply with all applicable law.'
                  : ''}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions leftButton={!loading && leftButton}>
        <Button
          onClick={handleSubmit}
          loading={loading}
          disabled={isCooldown}
          variant={'contained'}
          icon={LockIcon}
          id={'payrix-submit-button'}
        >
          Pay {paymentAmountAfterSurcharge}
        </Button>
      </DialogActions>
    </Dialog>
  )

  function openTermsDialog() {
    const { content } = getTerms({ quote, options })

    openDialog(ConfirmationDialog, {
      title: '',
      classes: { content: classes.termsDialogContent },
      message: <HtmlContent content={content} className={classes.termsContent} />,
      yesLabel: 'OK',
      onConfirm: dismissDialog
    })
  }

  async function handleSubmit(event) {
    // note - this guy fires for stripe only, payrix hijacks the event via the payrix-submit-button id
    try {
      event.preventDefault()
      setLoading(true)
      const result = await onSubmitPayment()
      setLoading(false)
      if (result.error) {
        handleError(result.error)
      } else {
        handleSuccessfulPayment()
      }
    } catch (err) {
      setLoading(false)
      handleError(err)
    }
  }

  function handleError(error: any) {
    console.log('Error processing payment:')
    console.log(error.message ?? error)

    // If the stripe form is missing data the form will be re-rendered with the error message,
    // so we don't need to show a snackbar.
    if (!error?.message.includes('is incomplete')) {
      Sentry.captureException(error)
      if (error?.message.includes('declined')) {
        enqueueSnackbar('Card declined, unable to process payment', { variant: 'error' })
      }
      enqueueSnackbar('Unable to process payment.', { variant: 'error' })
    }
  }

  function handleSuccessfulPayment() {
    openDialog(PaymentProcessingDialog, {
      Title: Title(),
      amount: paymentAmountAfterSurcharge,
      activityId,
      classes,
      quote,
      balanceDue: quote.totals.balance_due,
      onConfirm
    })
  }
}

export default CollectPaymentDialog
