import type { QuoteDocument, QuoteTotals } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { roundHours } from '../util/round-hours'
import get from 'lodash/get'
import { fixNumber } from '../util/fix-number'
import { roundPrice } from '../util'

export function discounts(quote: QuoteDocument): QuoteTotals {
  const newTotal = { ...quote.totals }

  let totalVisiblePriceDiscount = 0.0
  let totalVisibleHoursDiscount = 0.0

  let totalHiddenPriceDiscount = 0.0
  let totalHiddenHoursDiscount = 0.0

  const totalPrice = parseNumber(newTotal.after_adjustment ? newTotal.after_adjustment : newTotal.price)
  const additionalWorkPrice = parseNumber(get(quote, 'totals.additionalWork.price', 0))
  const additionalWorkMaterials = parseNumber(get(quote, 'totals.additionalWork.materials', 0))

  const addedOptionsPrice = parseNumber(get(quote, 'totals.addedOptions.price', 0))
  const addedOptionsMaterials = parseNumber(get(quote, 'totals.addedOptions.materials', 0))

  const roundPrices = quote.options?.roundPrices

  let priceWithAdditional =
    totalPrice + additionalWorkPrice + additionalWorkMaterials + addedOptionsPrice + addedOptionsMaterials

  if (quote.discounts) {
    quote.discounts.forEach((discount) => {
      if (discount._deleted) {
        return
      }

      let price = 0.0
      let hours = 0.0

      if (
        discount.type &&
        (discount.type.value === 'value' || (typeof discount.type === 'string' && discount.type === 'amount'))
      ) {
        if (discount.hours && discount.calculate) {
          discount.calculated_price = parseNumber(discount.hours) * quote.hourly_rate
          price += discount.calculated_price
        } else if (discount.price) {
          price += parseNumber(discount.price)
        }

        if (discount.hours) {
          hours += parseNumber(discount.hours)
        }
      } else {
        // percentage
        if (discount.hours && discount.calculate) {
          const rate = parseNumber(discount.hours) * 0.01
          hours = (newTotal.hours + newTotal.prep) * rate
          discount.calculated_hours = roundHours(hours, quote)
          if (quote.version >= 10) {
            discount.calculated_price = roundPrice(discount.calculated_hours * quote.hourly_rate, roundPrices)
          } else {
            discount.calculated_price = discount.calculated_hours * quote.hourly_rate
          }

          hours += discount.calculated_hours
          price += discount.calculated_price
        } else if (discount.price) {
          const rate = parseNumber(discount.price) * 0.01
          if (quote.version >= 10) {
            discount.calculated_price = roundPrice(priceWithAdditional * rate, roundPrices)
          } else {
            discount.calculated_price = priceWithAdditional * rate
          }

          price += discount.calculated_price
        } else if (discount.hours) {
          const rate = parseNumber(discount.hours) * 0.01
          hours = (newTotal.hours + newTotal.prep) * rate
          discount.calculated_hours = roundHours(hours, quote)
          hours += discount.calculated_hours
        }
      }

      if (discount.visible) {
        totalVisiblePriceDiscount += price
      } else {
        totalHiddenPriceDiscount += price
      }

      if (discount.visibleWorkOrder) {
        totalVisibleHoursDiscount += hours
      } else {
        totalHiddenHoursDiscount += hours
      }
    })
  }

  priceWithAdditional -= totalHiddenPriceDiscount
  newTotal.hours -= totalHiddenHoursDiscount

  newTotal.total_discount += totalVisiblePriceDiscount

  newTotal.hours_after_discount =
    quote.version >= 3
      ? fixNumber({ number: newTotal.hours + newTotal.prep - totalVisibleHoursDiscount })
      : newTotal.hours + newTotal.prep - totalVisibleHoursDiscount

  newTotal.after_discount = priceWithAdditional - newTotal.total_discount

  return newTotal
}
