import { Grid } from '@ui/paintscout'
import React from 'react'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import type { Theme } from '@material-ui/core'
import { IconButton, makeStyles } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      borderBottom: `2px solid ${theme.palette.divider}`
    }
  },
  removeButton: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginRight: -6
    }
  },
  valueWrapper: {
    [theme.breakpoints.only('lg')]: {
      maxWidth: 165,
      minWidth: 165
    }
  },
  checkboxAndButton: {
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: theme.spacing(1)
    }
  }
}))

interface TaxDiscountLayoutProps {
  Description: JSX.Element
  Value: JSX.Element
  Checkbox: JSX.Element
  handleRemove: () => void
}

export default function TaxDiscountLayout(props: TaxDiscountLayoutProps) {
  const { Description, Value, Checkbox, handleRemove } = props
  const classes = useStyles()
  return (
    <Grid className={classes.root} container spacing={2} justifyContent="space-between" alignItems="flex-start">
      <Grid item xl={4} lg={4} md={8} sm={6} xs={12}>
        {Description}
      </Grid>
      <Grid item className={classes.valueWrapper} xl={3} md={4} sm={6} xs={12}>
        {Value}
      </Grid>
      <Grid item className={classes.checkboxAndButton} xl={4} lg={4} sm={9} xs={8}>
        {Checkbox}
      </Grid>
      <Grid item className={classes.checkboxAndButton}>
        <IconButton size="small" className={classes.removeButton} onClick={handleRemove}>
          <RemoveCircleIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
