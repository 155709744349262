/**
 * @module builder
 */
import type { QuoteDocument, QuoteOptions, OrderItem, OptionsDocument } from 'paintscout'
import type { QuoteItemConsumer, QuoteItemSection, QuoteItemView, RenderableSubItem } from '../../../index'
import { filterSubstrateAreas } from './index'

/**
 * @description Get renderableCrewSubstrate Sub items for this substrate
 *  aka - the areas the substrate is a part of
 * @returns RenderableSubItem[] - area sub items for substrate
 */
export default function getSubstrateAreas(args?: {
  quote: QuoteDocument
  substrateKey: string
  options: QuoteOptions
  clientOptions: OptionsDocument
  section: QuoteItemSection
  showValues: boolean
  consumer?: QuoteItemConsumer
  view?: QuoteItemView
  showGroup?: boolean
  groupKey?: string
  hideDimensions?: boolean
}): RenderableSubItem[] {
  const {
    substrateKey,
    options,
    clientOptions,
    section,
    quote,
    showValues,
    consumer,
    view,
    showGroup = false,
    groupKey = '',
    hideDimensions = false
  } = args

  const areas: RenderableSubItem[] = []

  // Check each area on quote for areaSubstrates matching the substrateKey
  quote.order.area.forEach((orderItem: OrderItem) => {
    const { key } = orderItem
    const area = quote?.areas[key]

    // Filter unwanted areas
    if (!area) {
      return
    }
    if (orderItem._deleted) {
      return
    }
    if (!area.substrateOrder) {
      return []
    }

    area.substrateOrder.forEach((areaSubstrateOrderItem: OrderItem) => {
      // Get info for each areaSubstrate order item in area,
      // Add to areas if we want it
      const substrateArea = filterSubstrateAreas({
        quote,
        options,
        area,
        clientOptions,
        areaSubstrateOrderItem,
        key,
        substrateKey,
        section,
        showValues,
        consumer,
        view,
        showGroup,
        groupKey,
        hideDimensions
      })

      // Add substrateArea to areas
      if (substrateArea) {
        areas.push(substrateArea)
      } else {
        return
      }
    })
  })

  return areas
}
