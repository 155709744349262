import { useEffect, useState } from 'react'
import type { FormProps } from './CollectPayrixPaymentDialog'
import type { DropdownSelectOption } from '@ui/paintscout'
import { DropdownSelect, Grid, Input, InputLabel, Spinner } from '@ui/paintscout'
import React from 'react'
import { usePayrixStyles } from './usePayrixStyles'
import { useTheme } from '@material-ui/core'
import { useCreatePaymentActivityMutation } from '@paintscout/api'
import type { PayrixTxnMetadata } from 'paintscout'
import { randomString } from '@paintscout/util/util'
import { useSnackbar } from 'notistack'
import * as Sentry from '@sentry/react'

export default function CreditCardForm(props: FormProps) {
  const classes = usePayrixStyles()

  const {
    amount, // dollars including surcharge
    companyId,
    handlePaymentError,
    handleSuccessfulPayment,
    merchantId,
    note,
    options,
    payFieldsLoading,
    paymentRequestId,
    quote,
    setActivityId,
    setPayFieldsLoading,
    setSubmitting,
    source,
    surcharge // dollars
  } = props
  const [country, setCountry] = useState<DropdownSelectOption>(
    options.options.companyCountry?.value === 'Canada'
      ? { label: 'Canada', value: 'CAN' }
      : { label: 'United States', value: 'USA' }
  )
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const [createPaymentActivity] = useCreatePaymentActivityMutation()
  useEffect(() => {
    let submitButton
    let handleSubmit
    let activityId

    const script = document.createElement('script')
    script.src = `${process.env.PAYRIX_API_URL}/payFieldsScript?spa=1`
    script.type = 'text/javascript'
    script.async = true
    script.onload = () => {
      const PayFields = (window as any).PayFields as any
      if (!PayFields) {
        console.log('PayFields not found')
        return
      }
      PayFields.config.apiKey = process.env.PAYRIX_PUBLIC_KEY
      PayFields.config.merchant = merchantId
      // we can't add a surcharge with PayFields directly, so we need to create a token and then create a txn from that token
      const useToken = surcharge > 0
      PayFields.config.mode = useToken ? 'token' : 'txn'
      PayFields.config.txnType = useToken ? 'capture' : 'sale'
      PayFields.config.amount = Math.round(amount * 100)

      PayFields.fields = [
        {
          type: 'number',
          element: '#number'
        },
        {
          type: 'name',
          element: '#name',
          values: {
            name: quote.contact ? `${quote.contact.first_name} ${quote.contact.last_name}` : null
          }
        },
        {
          type: 'cvv',
          element: '#cvv'
        },
        {
          type: 'expiration',
          element: '#expiration'
        }
      ]
      PayFields.customizations.style = {
        '.input': {
          ...theme.typography.body1,
          border: `1px solid ${theme.palette.grey.A400}`,
          borderRadius: 2,
          boxSizing: 'border-box',
          height: '47px',
          lineHeight: '1.4',
          padding: '10px 16px',
          width: '100%',
          fontSize: '1rem',
          color: theme.palette.text.primary
        },
        '.card-icon': {
          top: '8px'
        }
      }
      PayFields.customizations.placeholders = {
        '#payment_number': '1234 1234 1234 1234',
        '#expiration': 'MM / YY',
        '#payment_cvv': 'CVC'
      }

      PayFields.ready()

      // Set setPayFieldsLoading false after 1 second to prevent flickering
      setTimeout(() => {
        setPayFieldsLoading(false)
      }, 1000)

      handleSubmit = async () => {
        setSubmitting(true)
        setPayFieldsLoading(true)
        const country = (document.querySelector('#country') as HTMLInputElement).value
        const email = (document.querySelector('#email') as HTMLInputElement).value
        const zip = (document.querySelector('#zip') as HTMLInputElement).value

        // create an optimistic activityId and create the activity on success
        activityId = randomString({ length: 32, charset: 'abcdefghijklmnopqrstuvwxyz0123456789' })

        // 94/100 characters already used
        const payrixMeta: PayrixTxnMetadata = {
          activityId,
          companyId: companyId,
          env: process.env.DEPLOYMENT_STAGE
        }

        PayFields.config.additionalData = useToken
          ? { tokenDescription: JSON.stringify(payrixMeta) }
          : { txnDescription: JSON.stringify(payrixMeta) }

        PayFields.config.billingAddress = {
          country,
          email,
          zip
        }
        PayFields.submit()
      }

      submitButton = document.querySelector('#payrix-submit-button')
      submitButton?.addEventListener('click', handleSubmit)

      // Failure Callback
      PayFields.onFailure = function (err) {
        console.log('failure')
        setSubmitting(false)
        setPayFieldsLoading(false)
        const errors = (err.errors as any[]) || []
        console.log(errors)
        const error15 = errors.find((error) => error.code === 15)
        if (error15) {
          if (error15.field === 'customer.email') {
            enqueueSnackbar('Please enter a valid email address', { variant: 'error' })
            return
          }
          // Error code 15 is a validation error that isn't caught by the onValidationFailure callback
          // So we'll just set submitting to false and hope the error message shows up in the PayFields div or is obvious to the user
          // We'll also capture the error in Sentry if its not handled by a snackbar above
          enqueueSnackbar('Validation error. Please make sure all fields are complete.', { variant: 'error' })
          Sentry.captureException(err)
          return
        }
        handlePaymentError(err)
      }

      // Success Callback
      PayFields.onSuccess = async function (response) {
        // TODO: because we are doing this here, we could slide some data from response into the activity
        // the response data varies on whether we are doing a token or a txn though, currently providerDetails is typed to only accept txn data
        // create the payment activity only on success so we don't have to clean up on failure
        const activity = await createPaymentActivity({
          variables: {
            ach: false,
            amount,
            deposit: !quote.is_invoice,
            docId: quote._id,
            id: activityId,
            note: note?.length > 100 ? `${note.substring(0, 100)}...` : note,
            paymentRequestId,
            provider: 'payrix',
            source,
            isProcessing: true,
            surcharge
          }
        })

        const responseId = activity.data.createPaymentActivity.activity._id
        setActivityId(responseId)

        console.log('success')
        console.log(response)
        handleSuccessfulPayment()
      }

      PayFields.onValidationFailure = function () {
        console.log('onValidationFailure')
        setSubmitting(false)
        setPayFieldsLoading(false)
      }
    }

    document.head.appendChild(script)

    return () => {
      const PayFields = ((window as any).PayFields as any) || {}
      submitButton.removeEventListener('click', handleSubmit)
      document.head.removeChild(script)
      PayFields.unmountAll()
      ;(window as any).PayFields = undefined
    }
  }, [])

  return (
    <>
      {payFieldsLoading && <Spinner style={{ display: 'block', margin: '81px auto', width: 30 }} />}
      <Grid container item spacing={2} className={classes.root} style={{ display: payFieldsLoading ? 'none' : 'flex' }}>
        <Grid item xs={12} md={6} className={classes.payField}>
          <InputLabel className={classes.inputLabel} htmlFor="name">
            Full Name
          </InputLabel>
          <div id="name"></div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input fullWidth label={'Email'} id={'email'} defaultValue={quote.contact?.email} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.payField}>
          <InputLabel className={classes.inputLabel} htmlFor="number">
            Card Number
          </InputLabel>
          <div id="number"></div>
        </Grid>
        <Grid item xs={6} md={3} className={classes.payField}>
          <InputLabel className={classes.inputLabel} htmlFor="expiration">
            Expiration date
          </InputLabel>
          <div id="expiration"></div>
        </Grid>
        <Grid item xs={6} md={3} className={classes.payField}>
          <InputLabel className={classes.inputLabel} htmlFor="cvv">
            Security Code
          </InputLabel>
          <div id="cvv"></div>
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownSelect
            fullWidth
            label={'Country:'}
            options={[
              { label: 'United States', value: 'USA' },
              { label: 'Canada', value: 'CAN' }
            ]}
            variant="single"
            value={country}
            onChange={setCountry}
          />
          <input id="country" type="hidden" value={country.value} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            fullWidth
            label={country?.value === 'CAN' ? 'Postal Code:' : 'Zip:'}
            id={'zip'}
            placeholder={country?.value === 'CAN' ? 'A1A 1A1' : '12345'}
          />
        </Grid>
      </Grid>
    </>
  )
}
