import type { TableBodyProps, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import type { WithUseStyles } from '@ui/paintscout'
import { Checkbox, Table, TableBody, TableHead, useUser, AddItemButton } from '@ui/paintscout'
import type { QuoteItemSection, RenderableItem, RenderableItemSubstrate } from '@paintscout/util/builder'
import classnames from 'classnames'
import find from 'lodash/find'
import React, { useEffect, useRef, useState } from 'react'
import type { SortEndHandler, SortStartHandler } from 'react-sortable-hoc'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import type { SortableContainerProps, SortableElementProps } from 'react-sortable-hoc'
import ItemTableCell from './ItemTableCell'
import ItemTableRow from './ItemTableRow'
import type { ItemTableRowProps } from './ItemTableRow'

export type Item = RenderableItem | RenderableItemSubstrate

export interface ItemTableProps {
  items: Item[]
  selectedItems?: Item[]
  draggable?: boolean
  totals?: any
  checkboxes?: boolean
  showAddButton?: boolean
  addButtonLabel?: React.ReactNode
  addIndex?: number
  addButtonDraggable?: boolean
  section?: QuoteItemSection
  disabled?: boolean

  renderHeadColumns: () => JSX.Element | JSX.Element[]
  renderRowColumns: (item: Item, index?: number) => JSX.Element | JSX.Element[]

  /**
   * Renders an extra row at the end of the table, after the add button, for purpose
   * of showing a "totals"
   */
  renderTotalRowColumns?: () => JSX.Element

  onItemClick?: (event: React.MouseEvent<HTMLElement>, item: Item) => void
  onItemSelection?: (event: React.ChangeEvent<HTMLElement>, selectedItems: Item[]) => void
  onReorder?: (args: { items: Item[]; addIndex?: number }) => void
  onAddClick?: (event: React.MouseEvent<HTMLElement>, insertAfterKey: string, type?: string) => void
  onTotalsClick?: (event: React.MouseEvent) => any
  onDeselectItem?: (args: {
    section: 'bid' | 'options' | 'additional' | 'pending' | 'archived' | 'all'
    key?: string
  }) => void
  onItemAction?: (action: string, section: QuoteItemSection, item: RenderableItem) => void
}

const useStyles = makeStyles<Theme, ItemTableProps>(
  (theme) => ({
    root: {},
    tableHead: {
      '@media print': {
        display: 'block',
        '& tr': {
          display: 'block'
        }
      }
    },
    checkboxHeadCell: {},
    checkboxCell: {
      '@media print': {
        display: 'none'
      }
    },
    checkbox: {
      padding: 0
    },
    noBorder: {
      borderBottom: 'none'
    },
    addCell: {
      padding: '2px 0 0 0 !important',
      borderBottom: 'none'
    },
    draggingHelper: {
      zIndex: 2147483002,
      background: 'white',
      display: 'table',
      width: '100%',
      boxShadow: theme.shadows[1]
    },
    marginTop: {
      marginTop: '2px'
    }
  }),
  {
    name: 'ItemTable'
  }
)

const SortableTableRow: React.ComponentClass<ItemTableRowProps & SortableElementProps, any> =
  SortableElement(ItemTableRow)
const SortableTableBody: React.ComponentClass<TableBodyProps & SortableContainerProps, any> =
  SortableContainer(TableBody)

function ItemTable(props: ItemTableProps & WithUseStyles<typeof useStyles>) {
  const {
    renderHeadColumns,
    renderRowColumns,
    renderTotalRowColumns,
    draggable,
    onReorder,
    onItemSelection,
    selectedItems,
    checkboxes,
    items,
    addIndex,
    showAddButton,
    addButtonLabel,
    addButtonDraggable,
    onAddClick,
    onItemClick,
    onTotalsClick,
    section,
    disabled = false
  } = props
  const classes = useStyles(props)
  const sanitizedAddIndex = sanitizeAddIndex(addIndex, items.length)

  const { isCrew, isViewer } = useUser()
  const [isDragging, setDragging] = useState(false)
  const dragStart = useRef(0)

  useEffect(() => {
    // easiest way to toggle 'grabbing' cursor - https://github.com/clauderic/react-sortable-hoc/issues/328#issuecomment-524101055
    if (isDragging) {
      document.body.style.cursor = 'grabbing'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isDragging])

  function handleItemSelection(e: any, checked: boolean, item: RenderableItem) {
    e.stopPropagation()
    if (!onItemSelection) {
      return
    }
    onItemSelection(e, checked ? [...selectedItems, item] : selectedItems.filter((i) => i.key !== item.key))
  }

  function handleAllSelection(ev: any, checked: boolean) {
    return onItemSelection && onItemSelection(ev, checked ? items : [])
  }

  const handleSortStart: SortStartHandler = (_sort, _ev) => {
    dragStart.current = Date.now()
    setDragging(true)
  }

  const handleSortEnd: SortEndHandler = ({ newIndex, oldIndex }, ev) => {
    setDragging(false)
    const dragTime = Date.now() - dragStart.current

    const isAddButton = oldIndex === sanitizedAddIndex
    const wasMoved = newIndex !== oldIndex

    // treat it as a regular click if it wasn't moved and was dragged for < 1 second
    if (!wasMoved && dragTime < 1000) {
      if (isAddButton && ev) {
        onAddClick(ev as any, null, null)
      } else {
        onItemClick?.(ev as any, rows[oldIndex] as Item)
      }
    }

    if (onReorder) {
      // is reordering add button
      if (isAddButton) {
        onReorder({
          items,
          addIndex: newIndex === items.length ? -1 : sanitizeAddIndex(newIndex, items.length)
        })
      }
      // is reordering item
      else {
        let newAddIndex = addIndex

        // make temporary array with an undefined item to represent the add button
        const itemsWithAddButton = [...items.slice(0, sanitizedAddIndex), undefined, ...items.slice(sanitizedAddIndex)]

        // reorder the items with the add button
        const reorderedItems = arrayMove(itemsWithAddButton, oldIndex, newIndex)
          // filter out the add button
          .filter((x, index) => {
            // the add button index has changed
            if (x === undefined && index !== sanitizedAddIndex) {
              newAddIndex = index
            }

            return x !== undefined
          })

        onReorder({ items: reorderedItems, addIndex: newAddIndex })
      }
    }
  }

  function cancelDrag(event: any) {
    const td = find(event.path, { tagName: 'TD' }) as HTMLElement

    // no-drag class is added to ItemTableCell with `cancelDrag` prop
    if (td && td.classList && td.classList.contains('no-drag')) {
      return true
    }

    const className = event.srcElement.className
    // cancel drag if the click was on the checkbox or the action button
    if (
      className.baseVal?.includes('SvgIcon') ||
      (typeof className === 'string' && (className.includes('no-drag') || className.includes('SwitchBase')))
    ) {
      return true
    }

    return false
  }

  const rows = showAddButton
    ? // with add button
      [...items.slice(0, sanitizedAddIndex), { key: '__ADD_BUTTON__' }, ...items.slice(sanitizedAddIndex)]
    : // without add button
      items

  return (
    <div className={classes.root}>
      <Table component="table">
        <TableHead component="thead" className={classes.tableHead}>
          <ItemTableRow>
            {checkboxes && (
              <ItemTableCell isControl className={classnames(classes.checkboxCell, classes.checkboxHeadCell)}>
                <Checkbox
                  color="secondary"
                  className={classes.checkbox}
                  indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
                  checked={items.length > 0 && selectedItems.length === items.length}
                  onChange={handleAllSelection}
                />
              </ItemTableCell>
            )}
            {renderHeadColumns()}
          </ItemTableRow>
        </TableHead>
        <SortableTableBody
          // @ts-ignore - proper typing of `component` prop gets lost by Sortable wrapper
          component="tbody"
          updateBeforeSortStart={handleSortStart}
          onSortEnd={handleSortEnd}
          helperClass={`${classes.draggingHelper} draggingHelper`}
          lockAxis={'y'}
          pressDelay={150}
          shouldCancelStart={cancelDrag}
        >
          {rows.map((item, index) => {
            const isAddButton = index === sanitizedAddIndex
            const itemIsSelected = !!selectedItems.find((i) => i.key === item.key)
            return (
              <SortableTableRow
                disabled={
                  ((isViewer || isCrew) && section === 'bid') ||
                  !draggable ||
                  (index === sanitizedAddIndex && (!addButtonDraggable || rows.length === 1))
                }
                index={index}
                key={item.key}
                id={`item-${item.key}`}
                onClick={!isAddButton && onItemClick ? (ev) => onItemClick(ev, item as RenderableItem) : null}
                noBorder={showAddButton && (index === sanitizedAddIndex - 1 || isAddButton)}
              >
                {rowIsRenderableItem(item) ? (
                  <>
                    {checkboxes && (
                      <ItemTableCell
                        onClick={(e) => handleItemSelection(e, !itemIsSelected, item)}
                        className={classes.checkboxCell}
                        isControl
                        cancelDrag
                      >
                        <Checkbox
                          className={classes.checkbox}
                          checked={!!selectedItems.find((i) => i.key === item.key)}
                        />
                      </ItemTableCell>
                    )}
                    {renderRowColumns(item, index)}
                  </>
                ) : (
                  <ItemTableCell colSpan={100} className={classnames(classes.itemCell, classes.addCell)}>
                    <AddItemButton
                      className={!renderTotalRowColumns && !items.length ? classes.marginTop : ''}
                      disabled={disabled}
                      onClick={(ev) =>
                        onAddClick(ev, items[addIndex] && items[addIndex].key ? items[addIndex].key : null, section)
                      }
                    >
                      {addButtonLabel}
                    </AddItemButton>
                  </ItemTableCell>
                )}
              </SortableTableRow>
            )
          })}
          {renderTotalRowColumns && (
            <ItemTableRow key={'__totals_row__'} noBorder onClick={onTotalsClick}>
              {renderTotalRowColumns()}
            </ItemTableRow>
          )}
        </SortableTableBody>
      </Table>
    </div>
  )
}

/**
 * Returns the "real" index for the add button if the passed in
 * addIndex is -1 or undefined
 */
function sanitizeAddIndex(addIndex: number, numItems: number) {
  return addIndex === undefined || addIndex < 0 ? numItems : addIndex
}

/**
 * Type guard function for determining if a row is a RenderableItem
 * or the add button
 */
function rowIsRenderableItem(item: any): item is RenderableItem {
  return item.key !== '__ADD_BUTTON__'
}

ItemTable.defaultProps = {
  consumer: 'customer',
  draggable: true,
  addButtonLabel: 'Add',
  addButtonDraggable: true,
  selectedItems: [],
  checkboxes: true
}

export default ItemTable
